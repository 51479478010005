<template>
  <div>
    <img class="home_banner" src="../../static/img/关于顶部图.png" />
    <img class="color_img" src="../../static/img/渐变条.png" />
    <div class="about_div3" id="part1">
      <p class="head_title">- 公司介绍 -</p>
      <p class="title">仓储物流与人力资源服务专家</p>
      <p class="subtitle">
        明仑企业致力为客户转嫁风险，降低成本，让客户专注于核心业务发展。
      </p>
      <div class="company_div">
        <div class="company_simple_div">
          <p class="title">宁波明仑企业服务有限公司</p>
          <p class="line"></p>
          <p class="content">
            成立于2005年，企业秉承“务实求新，客户至上”的经营理念，凭借着优秀的项目团队、先进的运营理念，丰富的行业知识，根据客户实际情况灵活定制服务方案，为企业解决突增业务人员、季节性人员、特殊专业性人员需求。明仑以浙江、江苏、上海、成都为中心，覆盖全国5个省市自治区50余座城市的服务网络，通过“一地合作，全国联动”为客户提供便捷、高效的一站式服务。
          </p>
          <p class="content">
            明仑企业坚持为客户提供完善的企业供应链管理服务体系，从仓储运营管理、人力资源外包/派遣、设备租售增值配套及物流车队运输配送等业务，帮助客户从容应对各种用工需求并抓住新的发展机遇。
          </p>
        </div>
      </div>
    </div>
    <div class="about_div4" id="part2">
      <p class="head_title">- 公司发展 -</p>
      <p class="title">发展历程 | 战略规划</p>
      <CompanyHistory></CompanyHistory>
    </div>
    <div class="about_div" id="part3">
      <p class="head_title">- 四大优势 -</p>
      <p class="title">企业供应链管理 | 一站式服务体系</p>
      <GoodBanner></GoodBanner>
    </div>
    <div class="about_div2" id="part4">
      <p class="head_title">- 五大业务范围 -</p>
      <p class="title">专注外包 | 合作共赢</p>
      <BusinessBanner></BusinessBanner>
    </div>
  </div>
</template>

<script>
import BusinessBanner from "../../src/components/BusinessBanner.vue";
import GoodBanner from "../../src/components/GoodBanner.vue";
import CompanyHistory from "../../src/components/CompanyHistory.vue";
export default {
  data() {
    return {
      path: "/About",
    };
  },
  components: {
    BusinessBanner,
    CompanyHistory,
    GoodBanner,
  },
  watch: {
    $route(to, from) {
      if (to.path === "/About") {
        console.log(to.path);
        var param = this.$route.query.param;
        this.path = param;
        console.log("param:" + param);
        if(param==="top"){
          window.scrollTo(0,0);
        }else{
          this.navtoPartPath(this.path);
        }
      }
    },
  },
  mounted() {},
  methods: {
    navtoPartPath(path) {
      setTimeout(() => {
        document.getElementById(path).scrollIntoView();
      }, 500);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus" scoped>
.home_banner {
  width: 100%;
  display: block;
}

.head_title {
  font-size: 5rem;
  font-family: medium;
  color: var(--textBlue);
  margin: 0 0 3rem;
}

.company_div {
  width: 100%;
  height: 80rem;
  background-image: url('../../static/img/关于公司建筑.png');
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}

.company_simple_div {
  padding: 6rem 20rem;
  position: absolute;
  right: 0;
  bottom: 10%;
  height: 30rem;
  width: 100rem;
  background-image: url('../../static/img/关于文字背景图.png');
  background-repeat: no-repeat;
  background-size: contain;
  color: white !important;
  text-align: left !important;

  .title {
    font-size: 3rem !important;
    color: white !important;
    text-align: left !important;
  }

  .line {
    margin: 3rem 0;
    background: white;
    height: 1px;
  }

  .content {
    font-size: 1.7rem !important;
    color: white !important;
    text-align: left !important;
  }
}

.about_div3 {
  background: var(--bgGary);
  padding: 12rem 0 0;

  .title {
    margin: 1rem 0;
    color: var(--textBlack);
    font-size: 2rem;
    font-family: regular;
  }

  .subtitle {
    color: var(--textBlack);
    font-size: 1.6rem;
    margin: 0 0 5rem;
  }
}

.about_div {
  background: var(--bgGary);
  padding: 12rem 0;

  .title {
    margin: 1rem 0;
    color: var(--textBlack);
    font-size: 2rem;
    font-family: regular;
  }

  .subtitle {
    color: var(--textBlack);
    font-size: 1.6rem;
    margin: 0 0 5rem;
  }
}

.about_div2 {
  background: white;
  padding: 12rem 0;

  .title {
    color: var(--textBlack);
    font-size: 2rem;
    font-family: regular;
  }

  .subtitle {
    color: var(--textBlack);
    font-size: 1.6rem;
    margin: 0 0 5rem;
  }
}

.about_div4 {
  background: white;
  padding: 12rem 0 5rem;

  .title {
    color: var(--textBlack);
    font-size: 2rem;
    font-family: regular;
  }

  .subtitle {
    color: var(--textBlack);
    font-size: 1.6rem;
    margin: 0 0 5rem;
  }
}

@media screen and (max-width: 1000px) {
  .home_banner {
    height: 45vw;
    object-fit: cover;
  }

  .company_simple_div {
    height: auto;
    padding: 0;
    // margin:1rem;
    width: 100%;
    background-size: cover;
  }
}
</style>
