<template>
  <div>
    <div ref="mySwiper" class="swiper-container home_banner">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in List" :key="index">
          <!-- <img src="../../static/img/banner.png" /> -->
          <img :src="item.img" />
        </div>
      </div>
      <div class="swiper-pagination home_pagination"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      mySwiper: "",
      List: [
        {
          title: "",
          img: "",
        },
      ],
    };
  },
  watch: {
    //监听轮播数据变化后再创建实例
    List() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.playMultipleBanner();
        },300);
      });
    },
    $route(to, from) {
      if (to.path === "/") {
        // this.$nextTick(() => {
        //   this.playMultipleBanner();
        // });
        // console.log(this.mySwiper.autoplay.running);
        // console.log(this.mySwiper.autoplay.paused);
        // console.log(this.$refs.mySwiper.swiper.autoplay.paused);
        // console.log('stop')
        // this.mySwiper.autoplay.stop();
        // console.log('start')
        // console.log(this.mySwiper.autoplay.running)
        // this.mySwiper.autoplay.start();
        // this.mySwiper.slideToClosest(1000,true);
        // this.mySwiper.autoplay.run();
        // this.$refs.mySwiper.swiper.autoplay.paused = false;
        // console.log(this.$refs.mySwiper.swiper.autoplay.paused);
        // this.$refs.mySwiper.swiper.autoplay.start();
        // this.$refs.mySwiper.swiper.autoplay.run();
      }
    },
  },
  activated() {
    // if (this.$refs.swiper && this.$refs.swiper.swiper.autoplay.paused) {
    // this.mySwiper.autoplay.run()
    // }
  },
  created() {},
  computed: {},
  mounted() {
    // this.playMultipleBanner();
    this.getBanner();
  },
  destroyed() {
    // window.removeEventListener("scroll", this.scrollToTop);
    // clearInterval(this.timer);
    // this.timer = null;
  },
  methods: {
    playMultipleBanner() {
      var swiper1 = new Swiper(".home_banner", {
        slidesPerView: 1,
        loop: true,
        centeredSlides: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".home_pagination",
          clickable: true,
          renderBullet: function (index, className) {
            return (
              '<span class="' +
              className +
              '" style="width:24px;height:8px;border-radius:4px ">' +
              "</span>"
            );
          },
        },
      });
      this.mySwiper = swiper1;
    },
    getBanner() {
      let that = this;
      this.$axios
        .get("app/homebanner/list")
        .then(function (response) {
          console.log(response);
          var status = response.data.code;
          if (status === 0) {
            that.List = response.data.page.list;
            console.log(that.List);
            // sessionStorage.setItem("input", "null");
          }
          // console.log("that.List");
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>
<style lang="stylus" scoped>
.home_banner {
  height: 86rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  --swiper-pagination-color: var(--mainColor);
}

.swiper-slide {
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media screen and (min-width: 2000px) {
  // .swiper-pagination {
  // margin-top: 50vw;
  // }
}

@media screen and (max-width: 1000px) {
  .home_banner {
    height: 45vw;
  }
  .swiper-slide {
    img {
      // width: 100%;
      // height: 45vw;
      object-fit: cover;
    }
}
}
</style>