<template>
  <div>
    <img class="home_banner" src="../../static/img/案例顶部图.png" />
    <img class="color_img" src="../../static/img/渐变条.png" />
    <div class="case_top_div">
      <div class="case_title_div">
        <img class="logo" :src="Detail.logo" />
        <p class="title">{{ Detail.title }}{{ Detail.titleTwo }}</p>
      </div>
      <div v-html="Detail.content" class="case_html"></div>
      <div class="num_div" v-if="Detail.numOne">
        <div>
          <div>
            <span class="simple_num">
              <!-- <count-to ref="mynum" :start-val='0' :end-val='List[0].yearNum' :duration='3000'></count-to> -->
              <countTo
                ref="mynum"
                :startVal="0"
                :endVal="Detail.numOne"
                :duration="3000"
                :separator="null"
              ></countTo> </span
            ><span class="simple_end">{{ Detail.numOneEnd }}</span>
          </div>
          <p class="simple_tv">{{ Detail.subNumOne }}</p>
        </div>
        <div v-if="Detail.numTwo">
          <div>
            <span class="simple_num">
              <!-- <count-to ref="mynum" :start-val='0' :end-val='List[0].yearNum' :duration='3000'></count-to> -->
              <countTo
                ref="mynum1"
                :startVal="0"
                :endVal="Detail.numTwo"
                :duration="3000"
                :separator="null"
              ></countTo> </span
            ><span class="simple_end">{{ Detail.numTwoEnd }}</span>
          </div>
          <p class="simple_tv">{{ Detail.subNumTwo }}</p>
        </div>
        <div v-if="Detail.numThree">
          <div>
            <span class="simple_num">
              <!-- <count-to ref="mynum" :start-val='0' :end-val='List[0].yearNum' :duration='3000'></count-to> -->
              <countTo
                ref="mynum2"
                :startVal="0"
                :endVal="Detail.numThree"
                :duration="3000"
                :separator="null"
              ></countTo> </span
            ><span class="simple_end">{{ Detail.numThreeEnd }}</span>
          </div>
          <p class="simple_tv">{{ Detail.subNumThree }}</p>
        </div>
      </div>
    </div>
    <div class="case_cover_div">
      <img class="case_cover" :src="Detail.cover" />
      <div class="case_info_div">
        <div class="case_sub_div">
          <img src="../../static/img/案例服务icon.png" />
          <div class="case_info_subdiv">
            <p class="case_info_title">服务内容 :</p>
            <p class="case_info">
              {{ Detail.service }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="news_more">
      <p @click="navto('/CaseDetail/id=' + prveNewsId)">
        上一篇：{{ prveNewsTitle }}
      </p>
      <p class="back_btn" @click="scrollNavto('/Case', 'part1')">返回</p>
      <p @click="navto('/CaseDetail/id=' + nextNewsId)">
        下一篇：{{ nextNewsTitle }}
      </p>
    </div>
  </div>
</template>

<script>
import countTo from "vue-count-to";
export default {
  data() {
    return {
      prveNewsTitle: "",
      prveNewsId: "",
      nextNewsTitle: "",
      nextNewsId: "",
      Detail: {},
      NewsList: [
        {
          id: "",
          logo: "",
          title: "",
          titleTwo: "",
          cover: "",
          simple: "",
          numOne: "",
          numTwo: "",
          numThree: "",
          numOneEnd: "",
          numTwoEnd: "",
          numThreeEnd: "",
          subNumOne: "",
          subNumTwo: "",
          sunNumThree: "",
        },
      ],
    };
  },
  watch: {
    $route(to, from) {
      window.scrollTo(0, 0);
      console.log(to.path);
      console.log(from.path);
      this.getDetail();
      this.getCase();
    },
  },
  components: {
    countTo,
  },
  mounted() {
    this.getDetail();
    this.getCase();
  },
  methods: {
    navto(path) {
      this.$router.push(path);
    },
    goback() {
      // this.$router.go(-1); //返回上一层
      this.$router.push("/Case");
    },
    scrollNavto(path, name) {
      this.$router.push({
        path: `${path}`,
        query: { param: name },
      });
    },
    getDetail() {
      let that = this;
      let id = window.location.href.split("id=")[1];
      this.$axios
        .get("app/case/info/id=" + id)
        .then(function (response) {
          console.log(response);
          var status = response.data.code;
          if (status === 0) {
            that.Detail = response.data.case;
            console.log(that.Detail);
            // sessionStorage.setItem("input", "null");
          }
        })
        .catch((err) => console.log(err));
    },
    getCase() {
      let that = this;
      this.$axios
        .get("app/case/list", {
          params: {
            page: 1,
            limit: 100,
          },
        })
        .then(function (response) {
          // console.log(response);
          var status = response.data.code;
          if (status === 0) {
            that.NewsList = response.data.page.list;
            console.log("case=" + that.NewsList.length);
            for (let i = 0; i < that.NewsList.length; i++) {
              let item = that.NewsList[i];
              if (item.id == window.location.href.split("id=")[1]) {
                if (i != 0) {
                  that.prveNewsTitle = that.NewsList[i - 1].title;
                  that.prveNewsId = that.NewsList[i - 1].id;
                } else {
                  that.prveNewsTitle = "无";
                }
                if (i < that.NewsList.length - 1) {
                  that.nextNewsTitle = that.NewsList[i + 1].title;
                  that.nextNewsId = that.NewsList[i + 1].id;
                } else {
                  // that.nextNewsTitle = "无";
                  that.nextNewsTitle = that.NewsList[0].title;
                  that.nextNewsId = that.NewsList[0].id;
                }
                // break;
              }
            }
            // console.log(that.NewsList);
            // sessionStorage.setItem("input", "null");
          }
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus" scoped>
.home_banner {
  width: 100%;
  display: block;
  object-fit: cover;
}

.num_div {
  margin: 10rem auto 0;
  width: 70%;
  display: flex;
  justify-content: space-evenly;
}

.simple_num {
  color: var(--textBlue);
  font-size: 8rem;
  font-family: mark-bold;
}

.simple_end {
  color: var(--textBlue);
  font-size: 7rem;
  font-family: bold;
}

.simple_tv {
  margin: 0;
  color: var(--textBlack);
  font-size: 1.7rem;
}

.case_top_div {
  width: 70%;
  margin: 10rem auto;

  .case_title_div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 5rem;

    .title {
      color: var(--textBlue);
      font-size: 5rem;
      font-family: bold;
      margin: 0 8rem;
    }

    .logo {
      height: 12rem;
      object-fit: contain;
    }
  }
}

.case_html {
  color: var(--textBlack);
  width: 80%;
  margin: auto;
  // text-align: left;
}

.case_cover_div {
  position: relative;
  margin: 10rem 0 20rem;

  .case_cover {
    width: 100%;
    height: 87rem;
    object-fit: cover;
  }
}

.case_sub_div {
  display: flex;
  align-items: baseline;
  width: 70%;
  float: right;

  img {
    width: 2rem;
    margin: 0 6rem 0 0;
    object-fit: contain;
  }
}

.case_info_div {
  width: 70%;
  background: var(--bgBlue80);
  padding: 6rem;
  position: absolute;
  display: inline-block;
  left: 0;
  bottom: -17.5rem;

  .case_info_title {
    color: white;
    font-size: 3rem;
    font-family: normal;
    text-align: left;
  }

  .case_info {
    margin: 4rem 10rem 0 0;
    text-align: left;
    color: white;
    font-size: 2rem;
  }
}

.news_more {
  padding: 10rem 0;
  width: 70%;
  margin: 1rem auto;
  display: flex;
  color: var(--textBlack);
  font-size: 1.6rem;
  font-family: light;
  justify-content: space-between;
  align-items: center;

  p {
    cursor: pointer;
    max-width: 30vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .back_btn {
    color: var(--textBlue);
    border: 1px solid var(--textBlue);
    border-radius: 1rem;
    padding: 0.5rem 2rem;
  }
}

@media screen and (max-width: 1000px) {
  .home_banner {
    height: 45vw;
    object-fit: cover;
  }

  .num_div {
    width: 95%;
  }

  .simple_num {
    font-size: 3rem;
  }

  .simple_end {
    font-size: 2rem;
  }

  .simple_tv {
    font-size: 1rem;
  }
  .case_html{
    width:100%;
  }
  .news_more{
    width:90%;
  }

  .case_top_div {
    width: 90%;

    .case_title_div {
      flex-direction: column;
    }
  }

  .case_info_div {
    width: 90%;
    padding: 2rem;
    bottom: -12.5rem;
  }

  .case_sub_div {
    width: 100%;

    img {
      margin: 0 2rem 0 0;
    }

    .case_info_title {
      margin: 1rem;
    }

    .case_info {
      margin: 1rem;
    }
  }
}
</style>
