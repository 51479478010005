<template>
  <div>
    <img class="home_banner" src="../../static/img/新闻顶部图.png" id="part1"/>
    <img class="color_img" src="../../static/img/渐变条.png" />
    <div class="news_div">
      <p class="head_title">- 新闻资讯 -</p>
      <el-row :gutter="10">
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
          v-for="(item, index) in NewsList"
          :key="index"
        >
          <div class="news_item" @click="navto('/NewsDetail/id=' + item.id)">
            <div class="news_top">
              <img :src="item.cover" />
              <div class="time_div">
                <p class="day">
                  {{ item.time.split("-")[1] }}月{{ item.time.split("-")[2] }}号
                </p>
                <p class="line"></p>
                <p class="year">{{ item.time.split("-")[0] }}</p>
              </div>
            </div>
            <div class="new_info">
              <p class="title">{{ item.title }}</p>
              <p class="hr_line" />
              <p class="content">
                {{ item.simple }}
              </p>
              <a class="click_more_white"
                >查看更多
                <div class="icon_img"></div
              ></a>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      path: "part1",
      NewsList: [
        {
          title: "",
          img: "",
          simple: "",
          time: "",
        },
      ],
    };
  },
  watch: {
    $route(to, from) {
      if (to.path === "/News") {
        console.log(to.path);
         var param = this.$route.query.param;
        window.scrollTo(0,0);
        // this.path = param;
        // console.log("param:" + param);
        // this.navtoPartPath(this.path);
      }
    },
  },
  components: {},
  mounted() {
    this.getNews();
    window.scrollTo(0,0);
  },
  methods: {
    navtoPartPath(path) {
      setTimeout(() => {
        document.getElementById(path).scrollIntoView();
      }, 500);
    },
    getNews() {
      let that = this;
      this.$axios
        .get("app/news/list", {
          params: {
            page: 0,
            limit: 1000,
          },
        })
        .then(function (response) {
          console.log(response);
          var status = response.data.code;
          if (status === 0) {
            that.NewsList = response.data.page.list;
            console.log(that.NewsList);
            // sessionStorage.setItem("input", "null");
          }
          // console.log("that.List");
        })
        .catch((err) => console.log(err));
    },
    navto(path) {
      this.$router.push(path);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus" scoped>
.home_banner {
  display: block;
  width: 100%;
  object-fit: cover;
}

.head_title {
  font-size: 5rem;
  font-family: medium;
  color: var(--textBlue);
  margin: 0 0 5rem;
}

.news_div {
  background: white;
  padding: 12rem 0;
  width: 70%;
  margin: auto;
}

.news_item {
  display: flex;
  justify-content: space-evenly;
  width: 80%;
  margin: 10rem auto 5rem;

  .news_top {
    width: 46rem;
    height: 34rem;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .new_info {
    width: 46rem;
    height: 34rem;
    position: relative;
  }

  .time_div {
    background: var(--bgBlue80);
    position: absolute;
    top: 0;
    left: 5rem;
    width: 13rem;
    height: 15rem;
    text-align: left;
    margin: -2rem 0 0;
    padding: 3rem;

    .line {
      color: white;
      background: white;
      height: 1px;
      width: 2rem;
      margin: 0;
    }

    .day {
      margin: 0;
      font-size: 2.5rem;
      color: white;
      font-family: normal;
    }

    .year {
      font-family: normal;
      font-size: 1.6rem;
      color: white;
    }
  }

  .title {
    color: var(--textBlack);
    font-size: 2.5rem;
    font-family: regular;
    text-align: left;
    margin: 2rem 0 0;
  }

  .hr_line {
    color: var(--bgBlue);
    background: var(--bgBlue);
    height: 1px;
    margin: 1rem 0 2rem;
  }

  .content {
    color: var(--textBlack);
    text-align: left;
    font-size: 1.6rem;
    margin: 0 0 5rem;
  }
}

.click_more_white {
  position: absolute;
  bottom: 0;
  left: 0;
  cursor: pointer;
  border: 1px solid var(--textBlue);
  width: fit-content;
  height: 3rem;
  background: white;
  color: var(--textBlue);
  font-size: 1.6rem;
  padding: 0.5rem 3rem;
  border-radius: 1rem;
  line-height: 3rem;
  float: left;

  .icon_img {
    display: inline-block;
    background-image: url('../../static/img/click_more_icon_blue.png');
    background-repeat: no-repeat;
    background-size: contain;
    height: 1rem;
    width: 4rem;
    margin: 0 0 0 2rem;
  }
}

.click_more_white:hover {
  background: var(--textBlue);
  color: white;

  .icon_img {
    background-image: url('../../static/img/click_more_icon.png');
  }
}

@media screen and (max-width: 1000px) {
  .home_banner {
    height: 45vw;
    object-fit: cover;
  }

  .news_div {
    width: 90%;
  }

  .news_item {
    flex-direction: column;

    .news_top {
      width: 100%;
    }

    .new_info {
      width: 100%;
      height: 28rem;
    }
  }
}
</style>
