<template>
  <div>
    <img class="home_banner" src="../../static/img/荣誉顶部图.png" id="part1" />
    <img class="color_img" src="../../static/img/渐变条.png" />
    <div class="honor_div">
      <div class="honor_title">
        <p class="head_title_white">- 荣誉资质 -</p>
        <p class="content">
          公司通过IS09001:2015质量管理体系、OHSAS18001:2007职业健康安全管理体系及ISO14001:2015环境管理体系认证，
          通过有效的实施与运行，使企业不断改进管理工作，全面提升企业的整体管理水平，从而以更好的服务水准面向客户。
        </p>
      </div>
      <img :src="List[1].img" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      path: "part1",
      List: [
        {
          title: "",
          img: "",
        },
        {
          title: "",
          img: "",
        },
      ],
    };
  },
  watch: {
    $route(to, from) {
      if (to.path === "/Brand") {
        console.log(to.path);
        var param = this.$route.query.param;
        window.scrollTo(0, 0);
        // this.path = param;
        // console.log("param:" + param);
        // this.navtoPartPath(this.path);
      }
    },
  },
  components: {},
  mounted() {
    this.getHonor();
    window.scrollTo(0, 0);
  },
  methods: {
    navtoPartPath(path) {
      setTimeout(() => {
        document.getElementById(path).scrollIntoView();
      }, 500);
    },
    getHonor() {
      let that = this;
      this.$axios
        .get("app/honor/list")
        .then(function (response) {
          console.log(response);
          var status = response.data.code;
          if (status === 0) {
            that.List = response.data.page.list;
            console.log(that.List);
            // sessionStorage.setItem("input", "null");
          }
          // console.log("that.List");
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus" scoped>
.home_banner {
  display: block;
  width: 100%;
}

.head_title_white {
  font-size: 5rem;
  font-family: medium;
  color: white;
  margin: 0 0 3rem;
}

.honor_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-items: center;

  .honor_title {
    background-image: url('../../static/img/honor_div_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding: 12rem 0 22rem;
  }

  .content {
    margin: 3rem 0 0;
    font-size: 1.6rem;
    color: white;
    text-align: justify;
    width: 50%;
    margin: auto;
  }

  img {
    width: 50%;
    margin-top: -10rem;
    margin-bottom: 10rem;
    object-fit: contain;
  }
}

@media screen and (max-width: 1000px) {
  .home_banner {
    height: 45vw;
    object-fit: cover;
  }

  .honor_div .content {
    width: 90%;
  }

  .honor_div {
    .honor_title {
      padding: 2rem 0 8rem;
    }

    img{
      width:90%;
      margin-top:-5rem;
    }
  }
}
</style>
