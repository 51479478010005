<template>
  <div>
    <HomeBanner></HomeBanner>
    <div class="about_div" id="part1">
      <p class="head_title">- 关于我们 -</p>
      <p class="title">仓储物流与人力资源服务专家</p>
      <p class="subtitle">
        明仑企业致力为客户转嫁风险，降低成本，让客户专注于核心业务发展。
      </p>

      <el-row :gutter="10" class="img_div">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12"
          ><div class="grid-content">
            <div class="content-hover"  @click="scrollNavto('/About', 'part3')">
              <img class="icon" src="../../static/img/about_icon_1.png" />
              <p class="title">4大优势</p>
              <p class="content">企业供应链管理 | 一站式服务体系</p>
              <a class="click_more"
                >查看更多<img src="../../static/img/click_more_icon.png"
              /></a>
            </div></div
        ></el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12"
          ><div class="grid-content-2">
            <div class="content-hover"  @click="scrollNavto('/About', 'part4')">
              <img class="icon" src="../../static/img/about_icon_2.png" />
              <p class="title">5大业务范围</p>
              <p class="content">专注外包 | 合作共赢</p>
              <a class="click_more"
                >查看更多<img src="../../static/img/click_more_icon.png"
              /></a>
            </div></div
        ></el-col>
      </el-row>
    </div>
    <div class="case_div" id="part2">
      <p class="head_title">- 案例展示 -</p>
      <HomeCaseBanner></HomeCaseBanner>
    </div>
    <div class="honor_div" id="part3">
      <div class="honor_title">
        <p class="head_title_white">- 荣誉资质 -</p>
        <p class="content">
          公司通过IS09001:2015质量管理体系、OHSAS18001:2007职业健康安全管理体系及ISO14001:2015环境管理体系认证，
          通过有效的实施与运行，使企业不断改进管理工作，全面提升企业的整体管理水平，从而以更好的服务水准面向客户。
        </p>
      </div>
      <img :src="List[0].img" />
      <a class="click_more_white" @click="navto('/Brand')"
        >查看更多
        <div class="icon_img"></div
      ></a>
    </div>
    <div class="news_div" id="part4">
      <p class="head_title">- 新闻资讯 -</p>
      <el-row :gutter="10">
        <el-col
          :xs="24"
          :sm="24"
          :md="8"
          :lg="8"
          :xl="8"
          v-for="(item, index) in NewsList"
          :key="index"
        >
          <div class="news_item">
            <div class="news_top">
              <img :src="item.cover" />
              <div class="time_div">
                <p class="day">
                  {{ item.time.split("-")[1] }}月{{ item.time.split("-")[2] }}号
                </p>
                <p class="line"></p>
                <p class="year">{{ item.time.split("-")[0] }}</p>
              </div>
            </div>
            <p class="title">{{ item.title }}</p>
            <p class="hr_line" />
            <p class="content">
              {{ item.simple }}
            </p>
            <a
              class="click_more_white"
              @click="navto('/NewsDetail/id=' + item.id)"
              >查看更多
              <div class="icon_img"></div
            ></a>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import HomeBanner from "../../src/components/HomeBanner.vue";
import HomeCaseBanner from "../../src/components/HomeCaseBanner.vue";
export default {
  data() {
    return {
      path: "part1",
      List: [
        {
          title: "",
          img: "",
        },
      ],
      NewsList: [
        {
          title: "",
          img: "",
          simple: "",
          time: "",
        },
      ],
    };
  },
  components: {
    HomeBanner,
    HomeCaseBanner,
  },
  watch: {
    $route(to, from) {
      if (to.path === "/") {
        console.log(to.path);
        var param = this.$route.query.param;
        this.path = param;
        console.log("param:" + param);
        if(param==="top"){
          window.scrollTo(0,0);
        }else{
          this.navtoPartPath(this.path);
        }
      }
    },
  },
  mounted() {
    this.getHonor();
    this.getNews();
  },
  methods: {
    scrollNavto(path, name) {
      this.$router.push({
        path: `${path}`,
        query: { param: name },
      });
    },
    navtoPartPath(path) {
      setTimeout(() => {
        document.getElementById(path).scrollIntoView();
      }, 500);
    },
    getHonor() {
      let that = this;
      this.$axios
        .get("app/honor/list")
        .then(function (response) {
          console.log(response);
          var status = response.data.code;
          if (status === 0) {
            that.List = response.data.page.list;
            console.log(that.List);
            // sessionStorage.setItem("input", "null");
          }
          // console.log("that.List");
        })
        .catch((err) => console.log(err));
    },
    getNews() {
      let that = this;
      this.$axios
        .get("app/news/list", {
          params: {
            page: 0,
            limit: 3,
          },
        })
        .then(function (response) {
          console.log(response);
          var status = response.data.code;
          if (status === 0) {
            that.NewsList = response.data.page.list;
            console.log(that.NewsList);
            // sessionStorage.setItem("input", "null");
          }
          // console.log("that.List");
        })
        .catch((err) => console.log(err));
    },
    navto(path) {
      this.$router.push(path);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus" scoped>
.about_div {
  background: var(--bgGary);
  padding: 12rem 0;

  .title {
    margin: 1rem 0;
    font-size: 2rem;
    font-family: regular;
  }

  .subtitle {
    color: var(--textBlack);
    font-size: 1.6rem;
    margin: 0 0 5rem;
  }

  .img_div {
    width: 80%;
    margin: 7rem auto !important;

    .grid-content {
      width: 65rem;
      height: 65rem;
      margin: auto;
      background-image: url('../../static/img/about_img_1.png');
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 1rem;
      box-shadow: 15px 15px 15px rgba(0, 0, 0, 0.2);
    }

    .grid-content-2 {
      width: 65rem;
      height: 65rem;
      margin: auto;
      background-image: url('../../static/img/about_img_2.png');
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 1rem;
      box-shadow: 15px 15px 15px rgba(0, 0, 0, 0.2);
    }

    .content-hover {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      p {
        color: white;
      }

      .title {
        color: white;
        font-size: 3rem;
        font-family: regular;
        margin: 3rem 0 0;
      }

      .content {
        color: white;
        font-size: 2rem;
        font-family: light;
      }

      .icon {
        width: 18rem;
        height: 18rem;
        object-fit: contain;
      }
    }

    .content-hover:hover {
      background: var(--bgBlue80);
      border-radius: 1rem;

      .click_more {
        display: block;
      }
    }

    .click_more {
      cursor: pointer;
      display: none;
      font-size: 1.6rem;
      color: white;
      margin: 3rem 0 0;

      img {
        height: 1rem;
        margin: 0 0 0 2rem;
      }
    }
  }
}

.head_title {
  font-size: 5rem;
  font-family: medium;
  color: var(--textBlue);
  margin: 0 0 3rem;
}

.head_title_white {
  font-size: 5rem;
  font-family: medium;
  color: white;
  margin: 0 0 3rem;
}

.case_div {
  background: white;
  padding: 12rem 0;
}

.honor_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-items: center;

  .honor_title {
    background-image: url('../../static/img/honor_div_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding: 12rem 0 22rem;
  }

  .content {
    margin: 3rem 0 0;
    font-size: 1.6rem;
    color: white;
    text-align: justify;
    width: 40%;
    margin: auto;
  }

  img {
    // height: 20rem;
    width: 50%;
    margin-top: -10rem;
    margin-bottom: 10rem;
    object-fit: contain;
  }
}

.click_more_blue {
  cursor: pointer;
  width: 17rem;
  height: 4rem;
  background: var(--bgBlue);
  color: white;
  font-size: 1.6rem;
  padding: 1rem 3rem;
  border-radius: 1rem;
  line-height: 4rem;
  float: left;

  img {
    height: 1rem;
    margin: 0 0 0 2rem;
  }
}

.click_more_white {
  cursor: pointer;
  border: 1px solid var(--textBlue);
  width: fit-content;
  height: 3rem;
  background: white;
  color: var(--textBlue);
  font-size: 1.6rem;
  padding: 0.5rem 3rem;
  border-radius: 1rem;
  line-height: 3rem;
  float: left;

  .icon_img {
    display: inline-block;
    background-image: url('../../static/img/click_more_icon_blue.png');
    background-repeat: no-repeat;
    background-size: contain;
    height: 1rem;
    width: 4rem;
    margin: 0 0 0 2rem;
  }
}

.click_more_white:hover {
  background: var(--textBlue);
  color: white;

  .icon_img {
    background-image: url('../../static/img/click_more_icon.png');
  }
}

.news_div {
  background: white;
  padding: 12rem 0;
  width: 80%;
  margin:10rem auto;
}

.news_item {
  width: 46rem;
  margin: 10rem 0;

  .news_top {
    width: 100%;
    height: 34rem;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .time_div {
    background: var(--bgBlue80);
    position: absolute;
    top: 0;
    left: 5rem;
    width: 13rem;
    height: 15rem;
    text-align: left;
    margin: -2rem 0 0;
    padding: 3rem;

    .line {
      color: white;
      background: white;
      height: 1px;
      width: 2rem;
      margin: 0;
    }

    .day {
      margin: 0;
      font-size: 2.5rem;
      color: white;
      font-family: normal;
    }

    .year {
      font-family: normal;
      font-size: 1.6rem;
      color: white;
    }
  }

  .title {
    color: var(--textBlack);
    font-size: 2rem;
    font-family: regular;
    text-align: left;
    margin: 2rem 0 0;
  }

  .hr_line {
    color: var(--bgBlue);
    background: var(--bgBlue);
    height: 1px;
    margin: 1rem 0 2rem;
  }

  .content {
    color: var(--textBlack);
    text-align: left;
    font-size: 1.6rem;
    font-family: exlight;
    margin: 0 0 5rem;
  }
}

@media screen and (max-width: 1000px) {
  .about_div {
    .img_div {
      .grid-content {
        width: 100%;
        height: 50rem;
      }

      .grid-content-2 {
        margin:5rem 0 0;
        width: 100%;
        height: 50rem;
      }
      .content-hover {
        .icon{
          width:8rem;
          height:8rem;
        }
      }
    }
  }
  .honor_div .content{
    width:90%;
  }
  .honor_div{
    .honor_title{
      padding:2rem 0 8rem;
    }
    img{
      width:90%;
      margin-top:-5rem;
    }
  }
}
</style>
