<template>
  <div>
    <div class="swiper_father">
      <div class="swiper-container mySwiper2">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide swiper2-slide"
            v-for="(item, index) in List"
            :key="index"
          >
            <img :src="item.img" />
          </div>
        </div>
      </div>
      <div class="swiper-container good_banner">
        <div class="swiper-button-prev swiper-button-prev1"></div>
        <div class="swiper-wrapper">
          <div
            class="swiper-slide good_slide case_item"
            v-for="(item, index) in List"
            :key="index"
          >
            <!-- <img src="../../static/img/banner.png" /> -->
            <div class="case_hover">
              <div class="case_title_div">
                <div class="case_hover_img_div">
                  <img class="normal_img" :src="item.logo" />
                  <img class="hover_img" :src="item.logoHover" />
                </div>
                <p class="title">{{ item.title }}</p>
                <div v-html="item.content" class="content"></div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="good_swpier_btn_div"> -->
        <div class="swiper-pagination good_pagination"></div>
        <div class="swiper-button-next swiper-button-next1"></div>
        <!-- </div> -->
      </div>
    </div>

    <div class="num_div">
      <div>
        <div>
          <span class="simple_num">
            <!-- <count-to ref="mynum" :start-val='0' :end-val='List[0].yearNum' :duration='3000'></count-to> -->
            <countTo
              ref="mynum"
              :startVal="0"
              :endVal="3500"
              :duration="3000"
              :separator="null"
              :autoplay="false"
            ></countTo> </span
          ><span class="simple_end">+</span>
        </div>
        <p class="simple_tv">3500多名员工</p>
      </div>
      <div>
        <div>
          <span class="simple_num">
            <!-- <count-to ref="mynum" :start-val='0' :end-val='List[0].yearNum' :duration='3000'></count-to> -->
            <countTo
              ref="mynum1"
              :startVal="0"
              :endVal="5"
              :duration="3000"
              :separator="null"
              :autoplay="false"
            ></countTo> </span
          ><span class="simple_end">+</span>
        </div>
        <p class="simple_tv">5个省份设立分公司</p>
      </div>
      <div>
        <div>
          <span class="simple_num">
            <!-- <count-to ref="mynum" :start-val='0' :end-val='List[0].yearNum' :duration='3000'></count-to> -->
            <countTo
              ref="mynum2"
              :startVal="0"
              :endVal="500"
              :duration="3000"
              :separator="null"
              :autoplay="false"
            ></countTo> </span
          ><span class="simple_end">+</span>
        </div>
        <p class="simple_tv">500多间宿舍</p>
      </div>
      <div>
        <div>
          <span class="simple_num">
            <!-- <count-to ref="mynum" :start-val='0' :end-val='List[0].yearNum' :duration='3000'></count-to> -->
            <countTo
              ref="mynum3"
              :startVal="0"
              :endVal="350"
              :duration="3000"
              :separator="null"
              :autoplay="false"
            ></countTo> </span
          ><span class="simple_end">+</span>
        </div>
        <p class="simple_tv">设备租售</p>
      </div>
      <div>
        <div>
          <span class="simple_num">
            <!-- <count-to ref="mynum" :start-val='0' :end-val='List[0].yearNum' :duration='3000'></count-to> -->
            <countTo
              ref="mynum4"
              :startVal="0"
              :endVal="30"
              :duration="3000"
              :separator="null"
              :autoplay="false"
            ></countTo> </span
          ><span class="simple_end">+</span>
        </div>
        <p class="simple_tv">30人招聘团队</p>
      </div>
    </div>
  </div>
</template>
<script>
import countTo from "vue-count-to";
export default {
  data() {
    return {
      canScroll: true,
      List: [
        {
          img: "",
          content: "",
          logo: "",
          logoHover: "",
          title: "",
        },
      ],
    };
  },
  watch: {
    //监听轮播数据变化后再创建实例
    List() {
      this.$nextTick(() => {
        this.playMultipleBanner();
      });
    },
    // $route(to, from) {
    //   if (to.path === "/About") {
    //     this.$nextTick(() => {
    //       this.playMultipleBanner();
    //     });
    //   }
    // },
  },
  created() {},
  components: {
    countTo,
  },
  mounted() {
    this.getGoodBanner();
    window.addEventListener("scroll", this.windowScrollListener);
  },
  destroyed() {
    window.removeEventListener("scroll", this.windowScrollListener);
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    windowScrollListener() {
      // 获取滚动条的高度
      var scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      var width = document.documentElement.clientWidth;
      // console.log("scrollTop=" + scrollTop);
      var limitScroll = 3600;
      if (width < 1000) {
        //手机端
        limitScroll = 1800;
      } else if (width < 1400) {
        limitScroll = 2300;
      } else {
        limitScroll = 3600;
      }
      if (scrollTop > limitScroll) {
        // 利用布尔值控制元素的显隐
        if (this.canScroll) {
          this.canScroll = false;
          this.handleScroll();
          // console.log("startScroll=" + scrollTop);
        }
      }
      if (scrollTop < 100) {
        // this.canScroll = true;
        // console.log("resetScroll=" + scrollTop);
      }
    },
    handleScroll() {
      // this.$refs.mynum.reset();
      this.$refs.mynum.start();
      this.$refs.mynum1.start();
      this.$refs.mynum2.start();
      this.$refs.mynum3.start();
      this.$refs.mynum4.start();
    },
    getGoodBanner() {
      let that = this;
      this.$axios
        .get("app/goodbanner/list")
        .then(function (response) {
          console.log(response);
          var status = response.data.code;
          if (status === 0) {
            that.List = response.data.page.list;
            console.log(that.List);
            // sessionStorage.setItem("input", "null");
          }
          // console.log("that.List");
        })
        .catch((err) => console.log(err));
    },
    playMultipleBanner() {
      

      var swiper2 = new Swiper(".mySwiper2", {
        loop: true,
        spaceBetween: 10,
        // autoplay: false,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        
      });

      var swiper = new Swiper(".good_banner", {
        slidesPerView: 4,
        spaceBetween: 0,
        loop: true,
        // autoplay: false,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },

        pagination: {
          el: ".good_pagination",
          clickable: true,
          renderBullet: function (index, className) {
            return (
              '<span class="' +
              className +
              '" style="margin:0 4px ">' +
              "</span>"
            );
          },
        },
        navigation: {
          nextEl: ".swiper-button-next1",
          prevEl: ".swiper-button-prev1",
        },
        thumbs: {
          swiper: swiper2,
        },
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.swiper_father {
  margin: 5rem 0 0;
  position: relative;
  overflow-x: hidden !important;
  height: 90rem;
}

.good_swpier_btn_div {
  display: flex;
}

.swiper-button-next {
  background-image: url('../../static/img/case_banner_right.png') !important;
  background-repeat: no-repeat;
  background-size: contain;
  color: transparent;
  height: 5rem;
  width: 5rem;
  top: 90%;
  margin-right: 70%;
  outline: none;
}

.swiper-button-prev {
  display: none;
  background-image: url('../../static/img/case_banner_left.png') !important;
  background-repeat: no-repeat;
  background-size: contain;
  color: transparent;
  height: 5rem;
  width: 5rem;
  top: 45%;
  margin-left: 23%;
  outline: none;
}

.swiper-button-prev:hover {
  opacity: 1;
  background-image: url('../../static/img/case_banner_left_hover.png') !important;
}

.swiper-button-next:hover {
  opacity: 1;
  background-image: url('../../static/img/case_banner_right_hover.png') !important;
}

.mySwiper2 {
  position: absolute;
  width: 30%;
  height: 100%;
}

.good_banner {
  display: inline-block;
  margin: 13rem -25% 0 0;
  height: 70rem;
  width: 100%;
  --swiper-pagination-color: var(--mainColor);
}

.swiper2-slide {
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.good_slide {
  width: 100%;
  height: 100%;
  transform: scale(0.9) translateY(-5%);
  transition: all 0.4s;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.good_pagination {
  display: inline-block;
  bottom: 0;
  top: 90%;
  right: -40%;
  width: auto;
}

.case_item {
  margin: 0;
  position: relative;
  border-radius: 40%;

  img {
    width: 90%;
    height: 90%;
    border-radius: 3rem;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.4);
  }
}

.case_hover {
  margin: auto;
  // display: none;
  height: 90%;
  width: 90%;
  background: white;
  position: absolute;
  bottom: 10%;
  left: 0;
  right: 0;
  border-radius: 3rem;

  .content {
    // color: var(--bgBlue) !important;
    font-size: 1.6rem;
    margin: 0;
    text-align: left;
  }
}

.case_hover_img_div {
  margin: 3rem 0 0;
  width: 100%;
  position: relative;
  height: 7rem;

  .normal_img {
    left: 0;
    display: block;
  }

  .hover_img {
    margin: auto;
    display: none;
  }
}

.case_title_div {
  height: 30%;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  align-items: center;
  margin: 5rem 5rem 0;

  img {
    width: 7rem;
    height: 7rem;
    object-fit: contain;
    box-shadow: none;
    border-radius: 0;
  }

  .title {
    text-align: left;
    margin: 2rem 0;
    padding: 1rem 3rem;
    width: 100%;
    border: none;
    border-radius: 0;
    color: var(--textBlue) !important;
    font-size: 2.5rem;
    font-family: normal;
  }
}

.click_more_white {
  cursor: pointer;
  width: 17rem;
  height: 4rem;
  color: white;
  font-size: 1.6rem;
  padding: 1rem 3rem;
  line-height: 4rem;
  float: left;

  .icon_img {
    display: inline-block;
    background-image: url('../../static/img/case_more_icon.png');
    background-repeat: no-repeat;
    background-size: contain;
    height: 1rem;
    width: 2rem;
    margin: 0 0 0 2rem;
  }
}

.swiper-slide-active, .swiper-slide-duplicate-active {
  transform: translateY(1%) scaleY(1);
  transition: all 0.4s;

  .case_hover {
    display: block;
    background: var(--bgBlue80);

    .content {
      color: white !important;
      text-align: left;
    }
  }

  .case_title_div {
    .title {
      margin: 4rem 0;
      width: fit-content;
      border: 1px solid white;
      border-radius: 1rem;
      color: white !important;
    }
  }

  .case_hover_img_div {
    .normal_img {
      display: none;
    }

    .hover_img {
      display: block;
    }
  }
}

.num_div {
  margin: 15rem auto 0;
  width: 70%;
  display: flex;
  justify-content: space-evenly;
}

.simple_num {
  color: var(--textBlue);
  font-size: 8rem;
  font-family: mark-bold;
}

.simple_end {
  color: var(--textBlue);
  font-size: 8rem;
  font-family: mark-bold;
}

.simple_tv {
  margin-left: 1rem;
  color: var(--textBlack);
  font-size: 1.7rem;
}

@media screen and (min-width: 2000px) {
  // .swiper-pagination {
  // margin-top: 50vw;
  // }
}

@media screen and (max-width: 1000px) {
  .swiper_father {
    height: 50rem;
  }

  .good_banner {
    height: 30rem;
  }

  .case_hover {
    .content {
      display: none;
    }
  }

  .case_title_div {
    margin: 1rem;
    
    img{
      width:5rem;
      height:5rem;
      object-fit :contain;
    }

    .title {
      text-align: center;
      margin: 2rem 0;
      padding: 1rem 1rem;
      font-size: 1.5rem;
    }
  }

  .case_hover_img_div {
    margin: 3rem 0 0;
    width: 100%;
    position: relative;
    height: 5rem;

    .normal_img {
      margin: auto;
      display: block;
    }

    .hover_img {
      object-fit :contain;
      margin: auto;
      display: none;
    }
  }

  .num_div {
    width: 95%;
  }

  .simple_num {
    font-size: 3rem;
  }

  .simple_end {
    font-size: 2rem;
  }

  .simple_tv {
    font-size: 1rem;
  }
}
</style>