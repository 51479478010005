<template>
  <div>
    <el-container>
      <el-container>
        <el-header class="pc_header">
          <div class="logo_div" @click="navto('/')">
            <img src="../../static/img/logo.png" />
          </div>
          <!-- :default-active="activeIndex.toString()" -->
          <el-menu
            :default-active="activeIndex.toString()"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
          >
            <el-menu-item index="1" @click="scrollNavto('/', 'top')"
              >&emsp;首页&emsp;</el-menu-item
            >
            <el-menu-item index="2" @click="scrollNavto('/About', 'top')"
              >关于我们</el-menu-item
            >
            <el-menu-item index="3" @click="scrollNavto('/Case', 'top')"
              >案例展示</el-menu-item
            >
            <el-menu-item index="4" @click="scrollNavto('/Brand', 'part1')"
              >荣誉资质</el-menu-item
            >
            <el-menu-item index="5" @click="scrollNavto('/News', 'part1')"
              >新闻资讯</el-menu-item
            >
            <el-menu-item index="6" @click="scrollNavto('/CallUs', 'part1')"
              >联系我们</el-menu-item
            >

            <div class="phone_div">
              <img src="../../static/img/top_phone_icon.png" />
              <p>4006-879-123</p>
            </div>
          </el-menu>
        </el-header>
        <el-header class="phone_header">
          <div class="logo_div" @click="navto('/')">
            <img src="../../static/img/logo.png" />
          </div>
          <div class="phone_mune_div" @click="showSlideMenu()">
            <img src="../../static/img/汉堡栏.png" />
          </div>
          <el-drawer
            class="phone_slide_mune"
            title="我是标题必须设置"
            :modal="false"
            :show-close="false"
            :with-header="false"
            :visible.sync="drawer"
            :direction="direction"
            :before-close="handleClose"
            :size="drawerWidth"
          >
            <el-menu
              :default-active="activeIndex.toString()"
              class="el-menu-vertical-demo slide_mune"
              background-color="#115FADAA"
              text-color="#fff"
              active-text-color="#fff"
            >
              <el-menu-item index="1" @click="scrollNavto('/', 'top')"
                >&emsp;首页&emsp;</el-menu-item
              >
              <el-menu-item index="2" @click="scrollNavto('/About', 'top')"
                >关于我们</el-menu-item
              >
              <el-menu-item index="3" @click="scrollNavto('/Case', 'top')"
                >案例展示</el-menu-item
              >
              <el-menu-item index="4" @click="scrollNavto('/Brand', 'part1')"
                >荣誉资质</el-menu-item
              >
              <el-menu-item index="5" @click="scrollNavto('/News', 'part1')"
                >新闻资讯</el-menu-item
              >
              <el-menu-item index="6" @click="scrollNavto('/CallUs', 'part1')"
                >联系我们</el-menu-item
              >
            </el-menu>
          </el-drawer>
        </el-header>
        <el-main>
          <keep-alive>
            <router-view :key="$route.fullpath"></router-view>
          </keep-alive>
        </el-main>
        <el-footer>
          <img class="color_img" src="../../static/img/渐变条.png" />
          <div>
            <el-row :gutter="10" class="foot_nav">
              <el-col :xs="8" :sm="8" :md="3" :lg="3" :xl="3" class="phone_foot_gone"
                ><div class="grid-content">
                  <p>首页</p>
                  <p @click="scrollNavto('/', 'part1')">企业概括</p>
                  <p @click="scrollNavto('/', 'part2')">案例展示</p>
                  <p @click="scrollNavto('/', 'part3')">荣誉资质</p>
                  <p @click="scrollNavto('/', 'part4')">新闻资讯</p>
                </div></el-col
              >
              <el-col :xs="8" :sm="8" :md="3" :lg="3" :xl="3" class="phone_foot_gone"
                ><div class="grid-content">
                  <p>关于我们</p>
                  <p @click="scrollNavto('/About', 'part1')">公司介绍</p>
                  <p @click="scrollNavto('/About', 'part2')">公司发展</p>
                  <p @click="scrollNavto('/About', 'part3')">四大优势</p>
                  <p @click="scrollNavto('/About', 'part4')">五大业务范围</p>
                </div></el-col
              >
              <el-col :xs="8" :sm="8" :md="3" :lg="3" :xl="3" class="phone_foot_gone"
                ><div class="grid-content">
                  <p>案例展示</p>
                  <p @click="scrollNavto('/Case', 'part1')">合作案例</p>
                  <p @click="scrollNavto('/Case', 'part2')">业务范围</p>
                  <p @click="scrollNavto('/Case', 'part3')">合作伙伴</p>
                </div></el-col
              >
              <el-col :xs="9" :sm="9" :md="3" :lg="3" :xl="3" class="phone_foot_gone"
                ><div class="grid-content">
                  <p>荣誉资质</p>
                  <p @click="scrollNavto('/Brand', 'part1')">荣誉资质</p>
                </div></el-col
              >
              <el-col :xs="8" :sm="8" :md="3" :lg="3" :xl="3" class="phone_foot_gone"
                ><div class="grid-content">
                  <p>新闻资讯</p>
                  <p @click="scrollNavto('/News', 'part1')">新闻资讯</p>
                </div></el-col
              >
              <el-col :xs="7" :sm="7" :md="3" :lg="3" :xl="3" class="phone_foot_gone"
                ><div class="grid-content">
                  <p>联系我们</p>
                  <p @click="scrollNavto('/CallUs', 'part1')">联系我们</p>
                </div></el-col
              >
              <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6"
                ><div class="grid-content ercode_div">
                  <img src="../../static/img/ercode.png" />
                  <p>扫一扫 关注我们</p>
                </div></el-col
              >
            </el-row>
            <hr class="foot_line" />
            <div class="beian_div">
              <a href="https://beian.miit.gov.cn/">备案号：浙ICP备17023526号</a>
              <p>版权所有 © 宁波明仑企业服务有限公司 保留一切权利</p>
            </div>
          </div>
        </el-footer>
      </el-container>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: 1,
      drawer: false,
      drawerWidth: "70%",
      direction: "rtl",
    };
  },
  watch: {
    $route(to, from) {
      console.log("to==" + to.path);
      if (to.path === "/") {
        this.activeIndex = 1;
      } else if (to.path === "/About") {
        this.activeIndex = 2;
      } else if (to.path === "/Brand") {
        this.activeIndex = 4;
      } else if (to.path === "/Case"||to.path.includes("/CaseDetail")) {
        this.activeIndex = 3;
      } else if (to.path === "/News"||to.path.includes("/NewsDetail")) {
        this.activeIndex = 5;
      } else if (to.path === "/CallUs") {
        this.activeIndex = 6;
      }
    },
  },
  components: {},
  mounted() {
    this.Init();
  },
  methods: {
    Init() {},
    navto(path) {
      this.drawer = false;
      this.$router.push(path);
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    scrollNavto(path, name) {
      this.drawer = false;
      this.$router.push({
        path: `${path}`,
        query: { param: name },
      });
    },
    showSlideMenu() {
      this.drawer = !this.drawer;
    },
    handleClose(done) {
      done();
      // this.$confirm('确认关闭？')
      //   .then(_ => {
      //     done();
      //   })
      //   .catch(_ => {});
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus" scoped>
.pc_header {
  display: flex !important;
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
  background: white;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
}

.phone_header {
  display: none !important;
  justify-content: space-between !important;
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
  background: white;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);

  .phone_mune_div {
    // width: auto;
    height: 4rem;
    

    img {
      width: 100%;
      height: 100%;
      object-fit :contain;
    }
  }
}

.phone_slide_mune {
  margin: 10rem 0 0;
  background-color: transparent;
}

::v-deep .el-drawer {
  width: 70%;
  background-color: transparent !important;
}

.slide_mune {
  background: var(--bgBlue80);
  height: 100%;

  .el-menu-item {
    background-color: transparent !important;
  }

  .el-menu-item.is-active {
    background-color: var(--bgBlue) !important;
  }
}

.el-header {
  text-align: center;
  height: 10rem !important;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: light;
}

.el-menu-demo {
  height: 100%;
}

.el-menu.el-menu--horizontal {
  border-bottom: none;
}

.el-menu--horizontal>.el-menu-item {
  font-family: light;
  font-size: 1.6rem;
  height: 10rem;
  line-height: 10rem;
  color: var(--textBlack);
  text-decoration: none;
  border-bottom: transparent !important;
}

.el-menu--horizontal>.el-menu-item:hover {
  border-bottom: transparent !important;
  background: var(--bgBlue) !important;
  color: white !important;
}

.el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: transparent !important;
  background: var(--bgBlue) !important;
  color: white !important;
}

.el-aside {
  background-color: #D3DCE6;
  color: #333;
  text-align: center;
  line-height: 200px;
}

.el-main {
  background-color: white;
  padding: 0;
}

body > .el-container {
  margin-bottom: 40px;
}

.logo_div {
  width: fit-content;
  float: left;
  height: 7rem;

  img {
    height: 100%;
    vertical-align: middle;
    object-fit: contain;
  }
}

.phone_div {
  height: 100%;
  width: fit-content;
  display: flex;
  align-items: center;

  img {
    height: 3.5rem;
    vertical-align: middle;
    object-fit: contain;
    margin: 0 2rem 0 10rem;
  }

  p {
    font-size: 3rem;
    font-family: bold;
    color: var(--phoneBlue);
  }
}

.el-footer {
  background: var(--footBgBlue);
  text-align: center;
  height: fit-content !important;
  padding: 0;

  .grid-content {
    p:first-child {
      cursor: auto;
      font-size: 2rem;
      font-family: normal;
      margin: 0 0 1.6rem;
    }

    p {
      cursor: pointer;
      text-align: left;
      color: white;
      margin: 1rem 0;
    }
  }
}

.ercode_div {
  img {
    width: 13.5rem;
    object-fit: contain;
  }

  p {
    text-align: center !important;
  }
}

.foot_nav {
  width: 80%;
  margin: 5rem auto 0 !important;
}

.foot_line {
  background: white;
  margin: 5rem 0 0;
}

.beian_div {
  display: flex;
  justify-content: space-between;

  a {
    font-family: exlight;
    font-size: 1.6rem;
    color: white;
    margin: 1rem;
    text-decoration: none;
  }

  a:link {
    text-decoration: none;
  }

  a:active {
    text-decoration: none;
  }

  a:visited {
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
  }

  p {
    color: white;
    margin: 1rem;
  }
}

@media screen and (max-width: 1000px) {
  .phone_foot_gone{
    display :none;
  }
  .pc_header {
    display: none !important;
  }

  .phone_header {
    display: flex !important;
  }

  .beian_div {
    flex-direction: column;
  }
}
</style>
