<template>
  <div class="swiperBox">
    <div class="threeImg">
      <div class="iconleft" @click="zuohua" :style="{ 'left': left +'px' }">
        <div src="../../static/img/case_banner_left.png" alt=""></div>
      </div>
      <div class="Containt" id="Containt">
        <ul :style="{'left':calleft + 'px', width: widthData + 'px'} " v-on:mouseover="stopmove()"
          v-on:mouseout="move()" class="imgBoxoul">
          <li v-for="(item,index) in CaseList" :key="index" ref="lis">
            <div v-for="(items,i) in item" :key="i" class="showimg" >
              <img :src="items.img" />
              <div class="overlay-box"></div>
            </div>
          </li>
        </ul>
      </div>
      <div class="iconright" @click="youhua" :style="{ right: left +'px' }">
        <div src="../../static/img/case_banner_right.png" alt=""></div>
      </div>
    </div>
    <div class="server-title">诚德铸品牌，信誉赢未来，我们期待与您的合作！</div>

  </div>
</template>
<script>
  export default {
    props: {
    },
    data() {
      return {
        calleft: 0,
        speed: 1,
        CaseList: [],
        left:''
      };
    },
    created() {
        this.move();
    },
    computed: {
      widthData() {
        if(window.innerWidth <= 1920){
          return 130 * Number(this.CaseList.length)
        }else{
          return 242 * Number(this.CaseList.length)
        }
      }
    },
    mounted() {
      this.getcase()
        window.addEventListener('scroll', this.scrollToTop)
        let clientWight = $('.Containt').css("margin-left");
        clientWight = clientWight.replace('px','')
        this.left = clientWight / 1.5
        window.onresize = function temp() {
          let clientWight = $('.Containt').css("margin-left");
          clientWight = clientWight.replace('px','')
          this.left = clientWight / 1.5
        }
        window.addEventListener('resize', () => 
          clientWight = $('.Containt').css("margin-left"),
          clientWight = clientWight.replace('px',''),
          this.left = clientWight / 1.5
        )
    },
    destroyed() {
      window.removeEventListener('scroll', this.scrollToTop)
      clearInterval(this.timer);
      this.timer = null;
    },
    methods: {
      //移动
      move() {
        this.timer = setInterval(this.starmove, 20);
      },
      //开始移动
      starmove() {
        let logowidth = document.getElementById('Containt').clientWidth
        let left = this.CaseList.length
        this.calleft -= 0.5; //*this.speed
        if (this.calleft <= -(this.widthData - logowidth)) {
          this.calleft = 0;
        }
      },
      //鼠标悬停时停止移动
      stopmove() {
        clearInterval(this.timer);
      },
      //点击按钮左移
      zuohua() {
        if(window.innerWidth <= 1920){
          this.calleft -= 130; //this.speed = 1
          if (this.calleft <= -(130 * Number(this.CaseList.length))) {
            this.calleft = 0;
          }
        }else{
          this.calleft -= 242; //this.speed = 1
          if (this.calleft <= -(242 * Number(this.CaseList.length))) {
            this.calleft = 0;
          }
        }
        
      },
      //点击按钮右移
      youhua() { //this.speed = -1
      if(window.innerWidth <= 1920){
          this.calleft += 130;
          if (this.calleft >= 0) {
            this.calleft = -(130 * Number(this.CaseList.length));
          }
        }else{
          this.calleft += 242;
          if (this.calleft >= 0) {
            this.calleft = -(242 * Number(this.CaseList.length));
          }
        }
      },
      gotodetails(id) {
        sessionStorage.setItem('business', '');
        sessionStorage.setItem('plate', '');
        let routeData = this.$router.resolve({
          path: "/Case/id=" + id,
        });
        window.open(routeData.href, '_blank');
      },
      getcase() {
        this.$axios
          .get("app/friends/list", {
            params: {
            page: 0,
            limit: 1000,
          },})
          .then(
            function (response) {
              var status = response.data.code;
              if (status === 0) {
                let CaseList = response.data.page.list;
                var result = [];
                for (var i = 0; i < CaseList.length; i += 4) {
                  result.push(CaseList.slice(i, i + 4));
                }
                this.CaseList = result
              } else {
                this.$message({
                  showClose: true,
                  type: "warning",
                  message: response.data.Result
                });
              }
            }.bind(this)
          )
      },
    },
  };
</script>
<style lang="stylus" scoped>
.server-title
  padding 5rem 0;
  font-size 2rem
  text-align center
  color:var(--textBlue);
  font-family:light;

.threeImg
  position: relative;
  width 100%
 
.threeImg .Containt ul {
  margin: 0;
  padding: 0;
  width: 2400px;
  position: absolute;
  left: 0px;
  cursor: pointer;
  height: 100%;
  z-index: 10;
  overflow hidden
}
 
.threeImg .Containt ul li
  float: left;
  width: 120px;
  height: 355px;
  margin-right: 10px;
  overflow: hidden;
  padding:1rem;
  .showimg 
    border-radius :1rem;
    box-shadow :5px 5px 5px rgba(0,0,0,0.2);
    display:flex;
    align-items :center;
    background:white;
    width: 120px;
    height: 68px
    margin-bottom: 20px;
.threeImg .Containt ul li .showimg:hover 
  opacity: 0.9;
  width: 120px;
  height: 68px;
  overflow hidden
  color: #fff;
  position: relative;
  .overlay-box
    width: 120px;
    height: 68px;
    display none
.threeImg .Containt ul li .showimg:hover .overlay-box
  flex-direction: column;
  background: rgba(0,0,0,0.5);
  display block
  position: absolute;
  bottom: 0;
  font-size: 16px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 68px;
  color: #ffffff;
.threeImg .Containt ul li img {
  width: 100%;
  height:50%;
  vertical-align :middle;
  object-fit :contain;
}
.swiperBox
  width:80%;
  margin: 0 auto;
  // background-color: #fbf9f9;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding 30px 0
  margin-top 30px
  .Containt
    position: relative;
    margin: auto;
    overflow-y: auto;
    width: 830px
    height: 355px;
    overflow: hidden;
    li
      img
        width 120px
        height 68px
 
.iconleft
  position: absolute;
  top: 0;
  bottom 0
  display flex
  justify-content center
  align-items: center;
  left: 0;
  z-index: 999;
  cursor: pointer;
  div
    background-image :url('../../static/img/case_banner_left.png');
    width 5rem;
    height:5rem;
    background-repeat:no-repeat;
    background-size:contain;
.iconleft:hover{
  div{
    background-image :url('../../static/img/case_banner_left_hover.png');
  }
}
.iconright
  position: absolute;
  top: 0;
  bottom 0
  right: 0;
  display flex
  justify-content center
  align-items: center;
  z-index: 999;
  cursor: pointer;
  div
    background-image :url('../../static/img/case_banner_right.png');
    width 5rem;
    height:5rem;
    background-repeat:no-repeat;
    background-size:contain;

.iconright:hover{
  div{
    background-image :url('../../static/img/case_banner_right_hover.png');
  }
}

@media screen and (max-width:1110px){
   .swiperBox .Containt{
        width 80%
    }    
    .iconleft {
      display none
    }
    
    .iconright {
      display none
    }
}
@media screen and (max-width:414px){
  .swiperBox{
    width calc(100% - 10px)
    padding 0
    margin 0 5px
  }
  .server-title{
    padding 5rem 0;
  }
  .iconleft {
    display none
  }
  .iconright {
    display none
  }
}
// 大屏
@media (min-width:1920px) 
  .swiperBox
    margin 0 auto
    padding-top 100px
    padding-bottom: 100px;
  .server-title
    font-size: 2rem;
  .threeImg .Containt ul li .showimg:hover,.threeImg .Containt ul li .showimg:hover .overlay-box
    width: 222px;
    height: 123px;
  .threeImg .Containt ul li
    float: left;
    width: 222px;
    height: 600px;
    margin-right: 10px;
    overflow: hidden;
    .showimg
      width: 222px;
      height: 123px
  .swiperBox .Containt li img
    width: 222px;
    height: 123px;
  .threeImg .Containt ul li
    margin-right 5px
    margin-bottom: 20px;
  .swiperBox .Containt
    width: 1442px;
    max-width 90%
    height: 600px;
</style>