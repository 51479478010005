<template>
  <div id="app">
    <div>
      <el-container>
        <!-- <el-aside width="200px">Aside</el-aside> -->
        <el-container>
          <el-header>
            <div class="nav_father">
              <div class="my_nav">
                <img @click="navto('/')" src="../../static/img/test.png" />
                <ul class="nav navbar-nav nav_ul" id="ul_header">
                  <li class="nav_li nav_home">
                    <a @click="navto('/')">首&emsp;&emsp;页</a>
                  </li>
                  <li class="nav_li nav_brand">
                    <a @click="showDownNav('Brand')">关于集壹</a>
                  </li>
                  <li class="nav_li nav_product">
                    <a @click="showDownNav('Products')">产品展示</a>
                  </li>
                  <li class="nav_li nav_download">
                    <a @click="showDownNav('Download')">咨询&下载</a>
                  </li>
                  <li class="nav_li nav_callus">
                    <a @click="navto('/CallUs')">联系我们</a>
                  </li>
                </ul>
              </div>
              <!-- <div class="nav_down" v-show="downnavshow">
        <div class="nav_down_brand" v-show="brandshow">
          <div class="nav_down_img" @click="scrollNavto('/Brand', 'brand')">
            <img src="../../static/img/关于-企业简介.png" />
            <p>企业简介</p>
          </div>
          <div
            class="nav_down_img"
            @click="scrollNavto('/Brand', 'brand_info')"
          >
            <img src="../../static/img/关于-品牌文化.png" />
            <p>品牌文化</p>
          </div>
        </div>
        <div class="nav_down_product" v-show="productshow">
          <div
            class="nav_down_img"
            @click="scrollNavto('/Products', 'bathroom')"
          >
            <img src="../../static/img/产品-浴室柜.png" />
            <p>卫浴洁具系列</p>
          </div>
          <div class="nav_down_img" @click="scrollNavto('/Products', 'valve')">
            <img src="../../static/img/产品-阀门管件.png" />
            <p>阀门管道系列</p>
          </div>
          <div class="nav_down_img" @click="scrollNavto('/Products', 'water')">
            <img src="../../static/img/产品-水泵.png" />
            <p>水泵机电系列</p>
          </div>
          <div class="nav_down_img" @click="scrollNavto('/Products', 'hvac')">
            <img src="../../static/img/产品-暖通.png" />
            <p>暖通流控系列</p>
          </div>
        </div>
        <div class="nav_down_video" v-show="videoshow">
          <div class="nav_down_img" @click="scrollNavto('/Download', 'video')">
            <img src="../../static/img/资讯-视频.png" />
            <p>视频中心</p>
          </div>
          <div
            class="nav_down_img"
            @click="scrollNavto('/Download', 'download')"
          >
            <img src="../../static/img/资讯-下载.png" />
            <p>下载中心</p>
          </div>
          <div class="nav_down_img" @click="scrollNavto('/Download', 'news')">
            <img src="../../static/img/资讯-新闻.png" />
            <p>新闻中心</p>
          </div>
        </div>
      </div> -->
            </div>
          </el-header>
          <el-main>
            <keep-alive>
              <router-view :key="$route.fullpath"></router-view>
            </keep-alive>
          </el-main>
          <el-footer>Footer</el-footer>
        </el-container>
      </el-container>
    </div>

    <!-- <div class="phone_nav">
      <div class="phone_my_nav">
        <img
          class="phone_logo"
          src="../../static/img/logo.png"
          @click="navto('/')"
        />
        <div class="phone_mune_div">
          <img
            v-show="!isOpenSlider"
            class="phone_mune_logo"
            src="../../static/img/汉堡栏.png"
            @click="openSilder()"
          />
          <img
            v-show="isOpenSlider"
            class="phone_mune_logo"
            src="../../static/img/nav_close.png"
            @click="openSilder()"
          />
        </div>
      </div>

      <div
        class="w3-sidebar w3-bar-block w3-animate-right"
        style="display: none"
        id="mySidebar"
      >
        <div class="siderbar_close" @click="closeSilder()">
          <p></p>
        </div>
        <div class="siderbar_div">
          <p class="w3-bar-item w3-button w3-item1" @click="navto('/')">
            首 页
          </p>
          <button
            class="w3-button w3-block w3-left-align w3-item1"
            @click="myAboutFunc()"
          >
            关于集壹 <i class="fa fa-caret-down"></i>
          </button>
          <div id="aboutAcc" class="w3-hide w3-white">
            <a
              class="w3-bar-item w3-button w3-item2"
              @click="scrollNavto('/Brand', 'brand')"
              >企业简介</a
            >
            <a
              class="w3-bar-item w3-button w3-item2"
              @click="scrollNavto('/Brand', 'brand_info')"
              >品牌文化</a
            >
          </div>
          <button
            class="w3-button w3-block w3-left-align w3-item1"
            @click="myProductFunc()"
          >
            产品展示 <i class="fa fa-caret-down"></i>
          </button>
          <div id="productAcc" class="w3-hide w3-white">
            <a
              class="w3-bar-item w3-button w3-item2"
              @click="scrollNavto('/Products', 'bathroom')"
              >卫浴洁具系列</a
            >
            <a
              class="w3-bar-item w3-button w3-item2"
              @click="scrollNavto('/Products', 'valve')"
              >阀门管道系列</a
            >
            <a
              class="w3-bar-item w3-button w3-item2"
              @click="scrollNavto('/Products', 'water')"
              >水泵机电系列</a
            >
            <a
              class="w3-bar-item w3-button w3-item2"
              @click="scrollNavto('/Products', 'hvac')"
              >暖通流控系列</a
            >
          </div>
          <button
            class="w3-button w3-block w3-left-align w3-item1"
            @click="myVideoFunc()"
          >
            资讯&下载 <i class="fa fa-caret-down"></i>
          </button>
          <div id="videoAcc" class="w3-hide w3-white">
            <a
              class="w3-bar-item w3-button w3-item2"
              @click="scrollNavto('/Download', 'video')"
              >视频中心</a
            >
            <a
              class="w3-bar-item w3-button w3-item2"
              @click="scrollNavto('/Download', 'download')"
              >下载中心</a
            >
            <a
              class="w3-bar-item w3-button w3-item2"
              @click="scrollNavto('/Download', 'news')"
              >新闻中心</a
            >
          </div>

          <a class="w3-bar-item w3-button w3-item1" @click="navto('/CallUs')"
            >联系我们</a
          >
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import HomeBanner from "../components/HomeBanner.vue";
export default {
  components: {
    HomeBanner,
  },
  name: "App",
  data() {
    return {
      fouce: true,
      LogoImg: "",
      WxGZHImg: "",
      mainurl: "",
      input: "",
      footer: [],
      activeIndex: "1",
      // clientWight: "",
    };
  },
  mounted() {
    // this.showActive();
  },
  methods: {
    navto(path) {
      // $(".collapse.in").removeClass("in");
      this.$router.push(path);
      this.showActive();
    },
    showActive() {
      $("#ul_header li").click(function (e) {
        $("#ul_header li").removeClass("active");
        $(this).addClass("active");
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.el-header, .el-footer {
  background-color: #B3C0D1;
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-aside {
  background-color: #D3DCE6;
  color: #333;
  text-align: center;
  line-height: 200px;
}

.el-main {
  background-color: #E9EEF3;
  color: #333;
  text-align: center;
  line-height: 160px;
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside, .el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}

.nav_father {
  display: block;
  width: 168rem;
  height: 8rem;
  margin: 0 auto 0;
  right: 0;
  left: 0;
  z-index: 1030;
  position: fixed;
}

.nav_down {
  width: 100%;
  height: 12rem;
  margin-top: 8rem;
  background: var(--textBlueColor60);
  display: flex;
  justify-items: center;
  align-items: center;

  .nav_down_brand {
    cursor: pointer;
    margin-left: 42%;
    display: flex;
    justify-items: center;
    align-items: center;
  }

  .nav_down_product {
    cursor: pointer;
    margin-left: 36%;
    display: flex;
    justify-items: center;
    align-items: center;
  }

  .nav_down_video {
    cursor: pointer;
    margin-left: 53%;
    display: flex;
    justify-items: center;
    align-items: center;
  }

  div {
    margin: 0 4rem;
  }

  img {
    width: 4rem;
    height: 4rem;
    object-fit: contain;
  }

  p {
    color: white;
    font-size: 1.6rem;
    font-family: regular;
    text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.4);
  }
}

.nav_down_img {
  transition: all 0.4s;
  // p{
  // margin-top:1rem;
  // }
}

.nav_down_img_margin {
  img {
    margin-bottom: 1rem;
  }
}

.nav_down_img:hover {
  transform: translateY(-0.5rem);
  transition: all 0.4s;
}

.phone_nav {
  display: none;
}

.phone_my_nav {
  height: 60px;
  align-items: center;
  background: white;
  display: flex;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1030;
  justify-content: space-between;

  .phone_logo {
    margin-left: 2rem;
    width: 16rem;
    height: auto;
  }

  .phone_mune_div {
    position: relative;
    width: 3rem;
    height: 100%;
    margin-right: 2rem;
  }

  .phone_mune_logo {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 100%;
    height: auto;
  }
}

.siderbar_close {
  width: 30%;
}

.siderbar_div {
  width: 70%;
  background: var(--textBlueColor60);
}

.w3-sidebar {
  display: flex;
  width: 100%;
  height: 100%;
  right: 0;
  margin-top: 60px;
  z-index: 10;
  background: transparent;
}

.w3-white {
  background-color: transparent !important;
}

.w3-green, .w3-hover-green:hover {
  background-color: var(--textBlueColor80) !important;
}

.fa {
  margin-left: 4rem;
}

.w3-item1 {
  background: transparent;
  padding: 2rem 2rem;
  font-size: 2rem;
  font-family: 'light';
  color: white;
  text-align: center !important;
  border: 1px solid var(--textWhiteColor20);
}

.w3-item2 {
  background: var(--textBlueColor80);
  padding: 2rem 2rem;
  font-size: 1.8rem;
  font-family: 'light';
  color: white;
  text-align: center !important;
  border: 1px solid var(--textWhiteColor20);
}

.w3-button:hover {
  text-decoration: none;
  color: white !important;
  background: var(--textBlueColor80) !important;
}

.my_nav {
  height: 100%;
  align-items: center;
  background: white;
  display: flex;
  width: 100%;
  position: absolute;
  z-index: 1030;

  img {
    object-fit: contain;
    margin-left: 5rem;
    width: 24.3rem;
    height: 3.3rem;
  }

  ul {
    list-style-type: none;
    margin: auto;
    padding: 0;
    overflow: hidden;
    background: white;
  }

  li {
    float: left;
    margin: 0 1rem;
  }
}
</style>
