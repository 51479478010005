<template>
  <div>
    <div class="swiper-container home_case_banner">
      <div class="swiper-button-prev swiper-button-prev1"></div>
      <div class="swiper-wrapper">
        <div
          class="swiper-slide case_item"
          v-for="(item, index) in List"
          :key="index"
        >
          <!-- <img src="../../static/img/banner.png" /> -->
          <img class="case_item_img" :src="item.cover" />
          <div class="case_hover">
            <div class="case_title_div">
              <p @click="navto('/CaseDetail/id=' + item.id)" class="title">
                {{ item.title }}{{ item.titleTwo }}
              </p>
              <a
                class="click_more_white"
                @click="navto('/CaseDetail/id=' + item.id)"
                >KNOW MORE
                <div class="icon_img"></div
              ></a>
            </div>
            <p class="content">
              {{ item.simple }}
            </p>
          </div>
        </div>
      </div>
      <div class="swiper-button-next swiper-button-next1"></div>
    </div>
    <div class="swiper-pagination home_case_pagination"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      List: [
        {
          title: "",
          cover: "",
          simple: "",
        },
      ],
    };
  },
  watch: {
    //监听轮播数据变化后再创建实例
    List() {
      this.$nextTick(() => {
        this.playMultipleBanner();
      });
    },
    // $route(to, from) {
    //   if (to.path === "/") {
    //     this.$nextTick(() => {
    //       this.playMultipleBanner();
    //     });
    //   }
    // },
  },
  created() {},
  computed: {},
  mounted() {
    // this.playMultipleBanner();
    this.getCaseBanner();
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop);
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    navto(path) {
      console.log(path);
      this.$router.push(path);
    },
    playMultipleBanner() {
      var swiper = new Swiper(".home_case_banner", {
        slidesPerView: 2,
        spaceBetween: 0,
        loop: true,
        centeredSlides: true,
        // autoplay: false,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: ".swiper-button-next1",
          prevEl: ".swiper-button-prev1",
        },
        pagination: {
          el: ".home_case_pagination",
          clickable: true,
          renderBullet: function (index, className) {
            return (
              '<span class="' +
              className +
              '" style="margin:0 4px ">' +
              "</span>"
            );
          },
        },
      });
    },
    getCaseBanner() {
      let that = this;
      this.$axios
        .get("app/case/list", {
          params: {
            page: 0,
            limit: 1000,
          },
        })
        .then(function (response) {
          console.log(response);
          var status = response.data.code;
          if (status === 0) {
            that.List = response.data.page.list;
            console.log(that.List);
            // sessionStorage.setItem("input", "null");
          }
          // console.log("that.List");
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>
<style lang="stylus" scoped>
.swiper-button-next {
  background-image: url('../../static/img/case_banner_right.png') !important;
  background-repeat: no-repeat;
  background-size: contain;
  color: transparent;
  height: 5rem;
  width: 5rem;
  top: 45%;
  margin-right: 21%;
  outline :none;
}

.swiper-button-prev {
  background-image: url('../../static/img/case_banner_left.png') !important;
  background-repeat: no-repeat;
  background-size: contain;
  color: transparent;
  height: 5rem;
  width: 5rem;
  top: 45%;
  margin-left: 21%;
  outline :none;
}

.swiper-button-prev:hover {
  opacity: 1;
  background-image: url('../../static/img/case_banner_left_hover.png') !important;
}

.swiper-button-next:hover {
  opacity: 1;
  background-image: url('../../static/img/case_banner_right_hover.png') !important;
}

.home_case_banner {
  height: 86rem;
  width: 100%;
  margin: 9rem 0 0;
  margin-left: auto;
  margin-right: auto;
  --swiper-pagination-color: var(--mainColor);
}

.swiper-slide {
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.home_case_pagination {
  width: 100%;
  margin: 3rem 0 0;
}

.case_item {
  margin: 0;
  position: relative;
  border-radius: 40%;

  img {
    width: 90%;
    height: 90%;
    border-radius: 3rem;
    // box-shadow: 15px 15px 15px rgba(0, 0, 0, 0.2);
  }
}

.swiper-slide-active {
  .case_hover {
    display: block;
  }
  .case_item_img{
      box-shadow: 15px 15px 15px rgba(0, 0, 0, 0.2);
  }
}

.case_hover {
  margin: auto;
  display: none;
  height: 40%;
  width: 90%;
  background: var(--bgBlue80);
  position: absolute;
  bottom: 10%;
  left: 0;
  right: 0;
  border-bottom-left-radius: 3rem;
  border-bottom-right-radius: 3rem;

  .content {
    color: white !important;
    font-size: 1.6rem;
    margin: 0 5rem;
    text-align: justify;
  }
}

.case_title_div {
  height: 30%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5rem 5rem 0;

  .title {
    color: white !important;
    font-size: 3rem;
    font-family: normal;
  }
}

.click_more_white {
  cursor: pointer;
  width: auto;
  height: 4rem;
  color: white;
  font-size: 1.6rem;
  padding: 1rem 0;
  line-height: 4rem;
  float: left;

  .icon_img {
    display: inline-block;
    background-image: url('../../static/img/case_more_icon.png');
    background-repeat: no-repeat;
    background-size: contain;
    height: 1rem;
    width: 2rem;
    margin: 0 0 0 2rem;
  }
}

@media screen and (max-width: 1000px) {
  .home_case_banner {
    height: 66rem;
  }
  .case_hover {
    height:15rem;
    .content{
      display :none;
    }
  }
  .click_more_white {
    display :none;

  }
}
</style>