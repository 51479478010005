<template>
  <div>
    <img class="home_banner" src="../../static/img/新闻顶部图.png" />
    <img class="color_img" src="../../static/img/渐变条.png" />
    <div class="news_div">
      <div class="news_top_div">
        <div class="news_title_div">
          <p class="title">{{ Detail.title }}</p>
          <p class="back_btn" @click="goback()">返回</p>
        </div>
        <p class="time">发布时间：{{ Detail.time }}</p>
      </div>
      <p></p>
      <div v-html="Detail.content" class="case_html"></div>
      <div class="line"></div>
      <div class="news_more">
        <p @click="navto(prveNewsId)">上一篇：{{ prveNewsTitle }}</p>
        <!-- <p class="back_btn" @click="goback()">返回</p> -->
        <p @click="navto(nextNewsId)">下一篇：{{ nextNewsTitle }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      prveNewsTitle: "",
      prveNewsId: "",
      nextNewsTitle: "",
      nextNewsId: "",
      Detail: {},
      NewsList: [
        {
          cover: "",
          time: "",
          title: "",
          simpleContent: "",
        },
      ],
    };
  },
  watch: {
    $route(to, from) {
      // console.log(to.path);
      window.scrollTo(0, 0);
      this.getDetail();
      this.getNews();
    },
  },
  components: {},
  mounted() {
    this.getDetail();
    this.getNews();
  },
  methods: {
    navto(path) {
      if (path) {
        this.$router.push("/NewsDetail/id=" + path);
      }
    },
    goback() {
      // this.$router.go(-1); //返回上一层
      this.$router.push("/News");
    },
    getDetail() {
      let that = this;
      let id = window.location.href.split("id=")[1];
      this.$axios
        .get("app/news/info/id=" + id)
        .then(function (response) {
          console.log(response);
          var status = response.data.code;
          if (status === 0) {
            that.Detail = response.data.news;
            console.log(that.Detail);
            // sessionStorage.setItem("input", "null");
          }
        })
        .catch((err) => console.log(err));
    },
    getNews() {
      let that = this;
      this.$axios
        .get("app/news/list", {
          params: {
            page: 1,
            limit: 100,
          },
        })
        .then(function (response) {
          // console.log(response);
          var status = response.data.code;
          if (status === 0) {
            that.NewsList = response.data.page.list;
            console.log("news=" + that.NewsList.length);
            for (let i = 0; i < that.NewsList.length; i++) {
              let item = that.NewsList[i];
              if (item.id == window.location.href.split("id=")[1]) {
                if (i != 0) {
                  that.prveNewsTitle = that.NewsList[i - 1].title;
                  that.prveNewsId = that.NewsList[i - 1].id;
                } else {
                  that.prveNewsTitle = "无";
                }
                if (i < that.NewsList.length - 1) {
                  that.nextNewsTitle = that.NewsList[i + 1].title;
                  that.nextNewsId = that.NewsList[i + 1].id;
                } else {
                  // that.nextNewsTitle = "无";
                  that.nextNewsTitle = that.NewsList[0].title;
                  that.nextNewsId = that.NewsList[0].id;
                }
                // break;
              }
            }
            // console.log(that.NewsList);
            // sessionStorage.setItem("input", "null");
          }
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus" scoped>
.home_banner {
  display: block;
  width: 100%;
  object-fit: cover;
}

.news_div {
  width: 60%;
  margin: auto;

  .title {
    font-size: 4rem;
    font-family: regular;
    position: absolute;
    left: 0;
    right: 0;
  }

  .time {
    color: var(--textBlack);
    font-size: 1.6rem;
    margin: 5rem 0 0;
  }
}

.news_top_div {
  margin: 10rem 0;
}

.news_title_div {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .back_btn {
    cursor: pointer;
    position: absolute;
    display: inline-block;
    right: 0;
    color: var(--textBlue);
    border: 1px solid var(--textBlue);
    border-radius: 1rem;
    padding: 0.5rem 2rem;
  }
}

.case_html {
  color: var(--textBlack);
  // text-align: left;
}

.line {
  height: 1px;
  background: var(--bgBlue);
}

.news_more {
  padding: 1rem 0 10rem;
  margin: 1rem auto;
  display: flex;
  color: var(--textBlack);
  font-size: 1.6rem;
  font-family: light;
  justify-content: space-between;
  align-items: center;

  p {
    cursor: pointer;
    max-width: 30vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .back_btn {
    color: var(--textBlue);
    border: 1px solid var(--textBlue);
    border-radius: 1rem;
    padding: 0.5rem 2rem;
  }
}

@media screen and (max-width: 1000px) {
  .home_banner {
    height: 45vw;
    object-fit: cover;
  }

  .news_div {
    width: 90%;

    .time {
      margin: 7rem 0 0;
    }
  }

  .news_title_div {
    .back_btn {
      display: none;
    }
  }
}
</style>
