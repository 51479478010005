<template>
  <div>
    <img class="home_banner" src="../../static/img/案例顶部图.png" />
    <img class="color_img" src="../../static/img/渐变条.png" />
    <div class="about_div" id="part1">
      <p class="head_title">- 合作案例 -</p>
      <p class="title">合作客户 | 服务内容</p>
      <el-row :gutter="10" class="case_div">
        <el-col
          :xs="12"
          :sm="12"
          :md="6"
          :lg="6"
          :xl="6"
          v-for="(item, index) in List"
          :key="index"
        >
          <div class="case_item" @click="navto('/CaseDetail/id=' + item.id)">
            <img :src="item.logo" />
            <div>
              <p>{{ item.title }}</p>
              <p>{{ item.titleTwo }}</p>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="about_div2" id="part2">
      <div class="map_bg">
        <div class="map_title_div">
          <p class="head_title">- 业务覆盖范围 -</p>
          <p class="title">服务客户布局</p>
        </div>

        <div class="msg_div">
          <div class="info_div">
            <p class="msg_content">
              <span class="msg_title">明仑企业</span>
              依托公司十余年的业务经验和强大的关系网络，业务得到了跳跃式的发展，经过十余年的努力发展，公司业务目前覆盖浙江宁波、杭州、上海、江苏、西安、成都、深圳等地区。
            </p>
            <p class="msg_content2">
              历经多年发展企业拥有一支高素质、高学历、爱岗敬业、经验丰富的服务团队，在全国7个省份设立分公司，为总部提供强有力的后援保障。而且形成了一套完善的管理体制和成熟周到的服务网络、建立了良好的人脉关系。业务贯穿仓储物流、互联网＋、人力资源劳务派遣、产线外包、法务服务、社保代理、人才猎头、薪酬福利外包及其他相关服务等多个领域，为企业提供全方位的服务解决方案。
            </p>
          </div>
          <div class="icon_div">
            <div class="icon_item">
              <div class="icon">
                <img src="../../static/img/map_icon1.png" />
              </div>
              <p>经验丰富</p>
            </div>
            <div class="icon_item">
              <div class="icon">
                <img src="../../static/img/map_icon2.png" />
              </div>
              <p>高效率</p>
            </div>
            <div class="icon_item">
              <div class="icon">
                <img src="../../static/img/map_icon3.png" />
              </div>
              <p>服务周到</p>
            </div>
            <div class="icon_item">
              <div class="icon">
                <img src="../../static/img/map_icon4.png" />
              </div>
              <p>爱岗敬业</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="about_div" id="part3">
      <p class="head_title">- 合作伙伴 -</p>
      <p class="title">致知力行 继往开来 | 筑梦前方 扬帆远航</p>
      <FriendsBanner></FriendsBanner>
    </div>
  </div>
</template>

<script>
import FriendsBanner from "../../src/components/FriendsBanner.vue";
export default {
  data() {
    return {
      path: "/Case",
      List: [
        {
          id: "",
          logo: "",
          title: "",
          titleTwo: "",
          cover: "",
          simple: "",
        },
      ],
    };
  },
  components: {
    FriendsBanner,
  },
  watch: {
    $route(to, from) {
      console.log(to.path);
      if (to.path === "/Case") {
      var param = this.$route.query.param;
      this.path = param;
      console.log("param:" + param);
      if(param==="top"){
          window.scrollTo(0,0);
        }else{
          this.navtoPartPath(this.path);
        }
      }
    },
  },
  mounted() {
    this.getCaseBanner();
  },
  methods: {
    navtoPartPath(path) {
      setTimeout(() => {
        document.getElementById(path).scrollIntoView();
      }, 500);
    },
    getCaseBanner() {
      let that = this;
      this.$axios
        .get("app/case/list", {
          params: {
            page: 0,
            limit: 1000,
          },
        })
        .then(function (response) {
          console.log(response);
          var status = response.data.code;
          if (status === 0) {
            that.List = response.data.page.list;
            console.log(that.List);
            // sessionStorage.setItem("input", "null");
          }
          // console.log("that.List");
        })
        .catch((err) => console.log(err));
    },

    navto(path) {
      this.$router.push(path);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus" scoped>
.home_banner {
  display: block;
  width: 100%;
  object-fit: cover;
}

.head_title {
  font-size: 5rem;
  font-family: medium;
  color: var(--textBlue);
  margin: 0 0 3rem;
}

.about_div {
  background: var(--bgGary);
  padding: 12rem 0;

  .title {
    color: var(--textBlack);
    font-size: 2rem;
    font-family: regular;
  }

  .subtitle {
    color: var(--textBlack);
    font-size: 1.6rem;
    margin: 0 0 5rem;
  }
}

.about_div2 {
  padding: 6rem 0;

  .title {
    color: var(--textBlack);
    font-size: 2rem;
    font-family: regular;
  }

  .subtitle {
    color: var(--textBlack);
    font-size: 1.6rem;
    margin: 0 0 5rem;
  }
}

.map_bg {
  position: relative;
  height: 150rem;
  width: 150rem;
  margin: auto;
  background-image: url('../../static/img/公司布局地图.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  .map_title_div {
    position: absolute;
    display: inline-block;
    top: 0;
    left: 0;
    right: 0;
    margin: 10rem auto;
  }
}

.case_div {
  width: 70%;
  margin: 0 auto !important;
}

.case_item {
  transition: all 0.4s;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  margin: 5rem auto;
  width: 26rem;
  height: 26rem;
  border-radius: 1rem;
  background: white;
  box-shadow: 0px 5px 23px 3px rgba(231, 231, 231, 0.8);

  img {
    display: block;
    width: 90%;
    object-fit: contain;
  }

  p {
    text-align: center;
    display: none;
    color: white;
    font-size: 5rem;
    font-family: medium;
    height: 100%;
  }
}

.case_item:hover {
  background: var(--bgBlue80);
  transform: scale(1.2);
  transition: all 0.4s;

  img {
    display: none;
  }

  p {
    display: block;
    line-height :1;
    margin:0;
  }
}

.msg_div {
  display: inline-block;
  position: absolute;
  left: 3%;
  top: 30%;

  .info_div {
    width: 60rem;
    background: var(--bgBlue80);
    border-radius: 1rem;
    padding: 5rem;

    .msg_title {
      color: white;
      font-size: 2.5rem;
      font-family: normal;
    }

    .msg_content {
      display: list-item;
      text-align: justify;
      color: white;
      font-size: 1.7rem;
    }

    .msg_content2 {
      display: list-item;
      margin: 5rem 0 0;
      text-align: justify;
      color: white;
      font-size: 1.7rem;
    }
  }

  .icon_div {
    display: flex;
    margin: 5rem 0 0;

    .icon_item {
      margin: 4rem 4rem 0 0;

      img {
        width: 6rem;
        height: 6rem;
        object-fit: contain;
      }

      p {
        color: var(--textBlack);
        margin: 1rem 0 0;
        font-size: 1.6rem;
        text-align: left;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .home_banner {
    height: 45vw;
    object-fit: cover;
  }

  .case_div {
    width: 90%;
  }

  .case_item {
    width: 20rem;
    height: 20rem;
    margin: 5rem auto 0;
    p{
      font-size:3rem;
    }
  }

  .map_bg {
    width: 100%;
  }

  .msg_div {
    left: 0;

    .info_div {
      width: auto;
    }
  }
}
</style>
