<template>
  <div>
    <img class="home_banner" src="../../static/img/联系顶部图.png" id="part1" />
    <img class="color_img" src="../../static/img/渐变条.png" />
    <div class="about_div">
      <p class="head_title">- 联系我们 -</p>
      <p class="title">诚德铸品牌，信誉赢未来。</p>
    </div>
    <div class="map_div">
      <div id="baidumap" class="map"></div>
      <div class="address_div">
        <div class="info_div">
          <p class="title">宁波明仑企业服务有限公司</p>
          <div class="address_item1">
            <img src="../../static/img/联系-电话.png" />
            <p>电话：400-879-123</p>
          </div>
          <div class="address_item1">
            <img src="../../static/img/联系-邮箱.png" />
            <p>邮箱：nbmlqy@163..com</p>
          </div>
          <div class="address_item1">
            <img src="../../static/img/联系-地址.png" />
            <p>地址：宁波市北仑区庐山西路90弄28号人力资源大厦9F</p>
          </div>
          <div class="address_item1">
            <img src="../../static/img/联系-网址.png" />
            <p>网址：www.nbmlqy.com</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      path: "part1",
    };
  },
  watch: {
    $route(to, from) {
      if (to.path === "/CallUs") {
        console.log(to.path);
        var param = this.$route.query.param;
        window.scrollTo(0,0);
        // this.path = param;
        // console.log("param:" + param);
        // this.navtoPartPath(this.path);
      }
    },
  },
  components: {},
  mounted() {
    this.Init();
    window.scrollTo(0,0);
  },
  methods: {
    navtoPartPath(path) {
      setTimeout(() => {
        document.getElementById(path).scrollIntoView();
      }, 500);
    },
    Init() {
      window.addEventListener("scroll", this.scrollToTop);
      this.initMap();
    },
    initMap() {
      var map = new BMap.Map("baidumap", {
        enableBizAuthLogo: false,
      });
      var point = new BMap.Point(121.792433, 29.914242);
      map.enableScrollWheelZoom(true);
      map.centerAndZoom(point, 18);
      map.addEventListener("zoomend", function () {
        // alert("地图缩放至：" + this.getZoom() + "级");
        map.panTo(new BMap.Point(121.792433, 29.914442));
      });
      window.setTimeout(function () {
        map.panTo(new BMap.Point(121.792433, 29.914442));
      }, 2000);
      var marker = new BMap.Marker(point); // 创建标注
      map.addOverlay(marker); // 将标注添加到地图中
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus" scoped>
.home_banner {
  display: block;
  width: 100%;
  object-fit: cover;
}

.head_title {
  font-size: 5rem;
  font-family: medium;
  color: var(--textBlue);
  margin: 0 0 3rem;
}

.about_div {
  background: white;
  padding: 12rem 0;

  .title {
    color: var(--textBlack);
    font-size: 2rem;
    font-family: regular;
  }

  .subtitle {
    color: var(--textBlack);
    font-size: 1.6rem;
    margin: 0 0 5rem;
  }
}

.address_div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100rem;
  background-image: url('../../static/img/联系文字背景图.png');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 6rem 10rem;
  margin: -13% auto 0;
  z-index: 1;

  .info_div {
    width: fit-content;
    margin: auto;
  }

  .title {
    color: white;
    font-size: 4rem;
    font-family: normal;
    text-align: left;
    padding: 0 0 3rem;
    border-bottom: 1px solid white;
    margin: 0;
  }

  .address_item1 {
    // border-top :1px solid white;
    border-bottom: 1px solid white;
    padding: 0.5rem;
    display: flex;
    // justify-content :center;
    align-items: center;

    img {
      width: 3rem;
      height: 3rem;
      object-fit: contain;
      margin: 1rem;
    }

    p {
      color: white;
      font-size: 2rem;
      text-align: left;
    }
  }
}

.map_div {
  position: relative;
  margin: 11% 0 0;
}

.map {
  width: 100%;
  height: 67rem;
}

@media screen and (max-width: 1000px) {
  .home_banner {
    height: 45vw;
    object-fit: cover;
  }

  .map_div {
    flex-direction: column-reverse;
    display: flex;
  }

  .address_div {
    width: auto;
    position: relative;
  }
}
</style>
