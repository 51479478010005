<template>
  <div>
    <div class="swiper-container business_banner">
      <div class="swiper-button-prev swiper-button-prev1"></div>
      <div class="swiper-wrapper">
        <div
          class="swiper-slide case_item"
          v-for="(item, index) in List"
          :key="index"
        >
          <img  class="case_item_img" :src="item.img" />
          <div class="case_hover">
            <div class="case_title_div">
              <img :src="item.logo" />
              <p class="title">{{ item.title }}</p>
              <div v-html="item.content" class="content"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-button-next swiper-button-next1"></div>
    </div>
    <div class="swiper-pagination business_pagination"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      List: [
        {
          img: "",
          content: "",
          logo: "",
          title: "",
        },
      ],
    };
  },
  watch: {
    //监听轮播数据变化后再创建实例
    List() {
      this.$nextTick(() => {
        this.playMultipleBanner();
      });
    },
    // $route(to, from) {
    //   if (to.path === "/About") {
    //     this.$nextTick(() => {
    //       this.playMultipleBanner();
    //     });
    //   }
    // },
  },
  created() {},
  computed: {},
  mounted() {
    this.getBusinessList();
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop);
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    getBusinessList() {
      let that = this;
      this.$axios
        .get("app/businessbanner/list")
        .then(function (response) {
          console.log(response);
          var status = response.data.code;
          if (status === 0) {
            that.List = response.data.page.list;
            console.log(that.List);
            // sessionStorage.setItem("input", "null");
          }
          // console.log("that.List");
        })
        .catch((err) => console.log(err));
    },
    playMultipleBanner() {
      var swiper = new Swiper(".business_banner", {
        slidesPerView: 2,
        spaceBetween: 0,
        loop: true,
        centeredSlides: true,
        // autoplay: false,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: ".swiper-button-next1",
          prevEl: ".swiper-button-prev1",
        },
        pagination: {
          el: ".business_pagination",
          clickable: true,
          renderBullet: function (index, className) {
            return (
              '<span class="' +
              className +
              '" style="margin:0 4px ">' +
              "</span>"
            );
          },
        },
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.swiper-button-next {
  background-image: url('../../static/img/case_banner_right.png') !important;
  background-repeat: no-repeat;
  background-size: contain;
  color: transparent;
  height: 5rem;
  width: 5rem;
  top: 45%;
  margin-right: 21%;
  outline :none;
}

.swiper-button-prev {
  background-image: url('../../static/img/case_banner_left.png') !important;
  background-repeat: no-repeat;
  background-size: contain;
  color: transparent;
  height: 5rem;
  width: 5rem;
  top: 45%;
  margin-left: 21%;
  outline :none;
}

.swiper-button-prev:hover {
  opacity: 1;
  background-image: url('../../static/img/case_banner_left_hover.png') !important;
}

.swiper-button-next:hover {
  opacity: 1;
  background-image: url('../../static/img/case_banner_right_hover.png') !important;
}

.business_banner {
  margin-top: 5rem;
  height: 90rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  --swiper-pagination-color: var(--mainColor);
}

.swiper-slide {
  width: 100%;
  height: 100%;
  transform: scaleY(0.8) translateY(-10%);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.business_pagination {
  width: 100%;
  margin: 3rem 0 0;
}

.case_item {
  margin: 0;
  position: relative;
  border-radius: 40%;

  img {
    width: 90%;
    height: 90%;
    border-radius: 3rem;
    // box-shadow: 15px 15px 15px rgba(0, 0, 0, 0.2);
  }
}


.case_hover {
  margin: auto;
  display: none;
  height: 90%;
  width: 90%;
  background: var(--bgBlue80);
  position: absolute;
  bottom: 10%;
  left: 0;
  right: 0;
  border-radius: 3rem;
}

.case_title_div {
  // height: 30%;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  align-items: center;
  margin: 0 5rem;

  img {
    margin: 0;
    width: 12rem;
    height: 12rem;
    object-fit: contain;
    box-shadow: none;
    border-radius: 0;
  }

  .title {
    margin: 3rem 0;
    padding: 1rem 3rem;
    width: fit-content;
    border: 1px solid white;
    border-radius: 1rem;
    color: white !important;
    font-size: 3rem;
    font-family: normal;
  }
}

.content {
  p {
    color: white !important;
  }

  color: white !important;
  font-size: 1.6rem;
  margin: 0 5rem;
  text-align: left;
}

.click_more_white {
  cursor: pointer;
  width: 17rem;
  height: 4rem;
  color: white;
  font-size: 1.6rem;
  padding: 1rem 3rem;
  line-height: 4rem;
  float: left;

  .icon_img {
    display: inline-block;
    background-image: url('../../static/img/case_more_icon.png');
    background-repeat: no-repeat;
    background-size: contain;
    height: 1rem;
    width: 2rem;
    margin: 0 0 0 2rem;
  }
}

.swiper-slide-active, .swiper-slide-duplicate-active {
  // transform: translateY(15%) scale(0.8);
  transform: translateY(5%);

  .case_hover {
    // display :block;
    display: flex;
    flex-direction :column;
    justify-content :space-evenly;
  }
  .case_item_img{
      box-shadow: 15px 15px 15px rgba(0, 0, 0, 0.2);
  }
}

@media screen and (max-width: 1600px) {
  .case_title_div {
    img {
      width: 10rem;
      height: 10rem;
    }
  }
}

@media screen and (max-width: 1000px) {
  .business_banner {
    height:40rem;
  }
  .case_title_div{
    margin:0 1rem;
    img{
      height:7rem;
      width:7rem;
    }

    .title{
      font-size:1.6rem;
    }
  }
  .content { 
    display :none;
  }
  .swiper-slide-active, .swiper-slide-duplicate-active {
  // transform: translateY(5%);

  .case_item_img{
      box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
  }
}
}
</style>