<template>
  <div class="map_bg">
    <div class="data_div">
      <div class="data_top_div">
        <el-row>
          <el-col :span="6"
            ><div class="grid-content bg-purple-dark"></div
          ></el-col>
          <el-col :span="6"
            ><div class="grid-content bg-purple-dark items_div">
              <div class="item">
                <p class="date">2017</p>
                <p class="simple">公司推进研发新媒体战略服务项目</p>
              </div>
              <div class="item">
                <p class="date">2014</p>
                <p class="simple">
                  正式更名“宁波明仑企业服务有限公司”公司通过ISO9001:2008质量管理体系及HSAS18001:2007职业健康安全管理体系认证
                </p>
              </div>
              <div class="item">
                <p class="date">2013</p>
                <p class="simple">
                  宁波保税区（出口加工区）授予我司"物流发展先进企业"长期合作公司达60余家。
                </p>
              </div>
            </div></el-col
          >
          <el-col :span="6"
            ><div class="grid-content bg-purple-dark"></div
          ></el-col>
          <el-col :span="6"
            ><div class="grid-content bg-purple-dark bottom_position">
              <div class="item">
                <p class="date">2021</p>
                <p class="simple">在职员工达到3500人以上</p>
              </div>
              <div class="item">
                <p class="date">2022</p>
                <p class="simple">
                  遵循"加快产业发展、促进均衡发展、实现协调共进"的发展思路，建立覆盖全国的产业网络。
                </p>
              </div>
            </div></el-col
          >
        </el-row>
      </div>
      <div class="data_mid_div">
        <el-row>
          <el-col :span="6"
            ><div class="grid-content bg-purple-dark">
              <div class="item">
                <p class="year">2005-2010</p>
                <div class="blue_line"></div>
                <img src="../../static/img/全球地图蓝色箭头.png" />
              </div></div
          ></el-col>
          <el-col :span="6"
            ><div class="grid-content bg-purple-dark">
              <div class="item">
                <img src="../../static/img/全球地图白色箭头.png" />
                <div class="white_line"></div>
                <p class="year">2010-2017</p>
              </div>
            </div></el-col
          >
          <el-col :span="6"
            ><div class="grid-content bg-purple-dark">
              <div class="item">
                <p class="year">2017-2020</p>
                <div class="blue_line"></div>
                <img src="../../static/img/全球地图蓝色箭头.png" />
              </div></div
          ></el-col>
          <el-col :span="6"
            ><div class="grid-content bg-purple-dark">
              <div class="item">
                <img src="../../static/img/全球地图白色箭头.png" />
                <div class="white_line"></div>
                <p class="year">2020-2022</p>
              </div>
            </div></el-col
          >
        </el-row>
      </div>
      <div class="data_bottom_div">
        <el-row>
          <el-col :span="6"
            ><div class="grid-content bg-purple-dark">
              <div class="item">
                <p class="date">2005</p>
                <p class="simple">公司正式成立</p>
              </div>
              <div class="item">
                <p class="date">2007</p>
                <p class="simple">
                  宁波保税区（出口加工区）管委会授予我司"劳动关系和谐企业"
                </p>
              </div>
              <div class="item">
                <p class="date">2010</p>
                <p class="simple">公司在职员工突破2000人以上</p>
              </div>
            </div></el-col
          >
          <el-col :span="6"
            ><div class="grid-content bg-purple-dark"></div
          ></el-col>
          <el-col :span="6"
            ><div class="grid-content bg-purple-dark">
              <div class="item">
                <p class="date">2018</p>
                <p class="simple">
                  研发并推广互联网+招聘平台，面向政府及企业事业单位提供相关服务。
                </p>
              </div>
              <div class="item">
                <p class="date">2019</p>
                <p class="simple">
                  持续推进互联网+服务平台，扩大平台知名度，切实提升平台的使用和访问量。
                </p>
              </div>
              <div class="item">
                <p class="date">2020</p>
                <p class="simple">
                  合作单位达100家以上,提供人力资源服务的同时，努力提升综合增值配套服务能力，体现供应链管理一站式服务的优越性。
                </p>
              </div>
            </div></el-col
          >
          <el-col :span="6"
            ><div class="grid-content bg-purple-dark"></div
          ></el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  computed: {},
  mounted() {},
  destroyed() {},
  methods: {},
};
</script>
<style lang="stylus" scoped>
.map_bg {
  margin: auto;
  background-image: url('../../static/img/全球地图.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 90%;
  height: 120rem;
  padding: 5rem 0 0;
}

.data_div {
  width: 80%;
  margin: auto;

  .data_top_div {
    .items_div {
      display: flex;
      flex-direction: column-reverse;
      height: 50rem;
    }

    .item {
      margin: 0 0 4rem;

      .date {
        font-size: 1.6rem;
        color: var(--textBlue);
        font-family: mark-medium;
        text-align: left;
        margin: 1rem 0;
      }

      .simple {
        color: var(--textBlack);
        border-top: 1px solid var(--textBlue);
        border-bottom: 1px solid var(--textBlue);
        padding: 1rem 0;
        font-size: 1.6rem;
        text-align: justify;
        margin: 0;
      }
    }

    .item:hover {
      .date {
        text-align: center;
        font-size: 2.5rem;
      }

      .simple {
        font-family:light;
        background: var(--bgBlue);
        padding: 1rem;
        font-size: 2rem;
        color: white;
      }
    }
  }

  .data_mid_div {
    .item {
      // height: 10rem;
      img {
        display: block;
        margin: 1rem auto;
        height: 4rem;
        width: 3rem;
        object-fit: contain;
      }
    }

    .year {
      margin: 1rem 0;
      height: 4rem;
      font-size: 3rem;
      color: var(--textBlue);
      font-family: mark-bold;
    }

    .blue_line {
      background: var(--bgBlue);
      height: 1.4rem;
    }

    .white_line {
      background: var(--blackBFBFBF);
      height: 1.4rem;
    }
  }

  .data_bottom_div {
    .item {
      margin: 0 0 4rem;

      .date {
        margin: 1rem 0;
        font-size: 1.6rem;
        color: var(--textBlue);
        font-family: mark-medium;
        text-align: left;
      }

      .simple {
        color: var(--textBlack);
        border-top: 1px solid var(--textBlue);
        border-bottom: 1px solid var(--textBlue);
        padding: 1rem 0;
        font-size: 1.6rem;
        text-align: justify;
        margin: 0;
      }
    }

    .item:hover {
      .date {
        text-align: center;
        font-size: 2.5rem;
      }

      .simple {
        background: var(--bgBlue);
        padding: 1rem;
        font-size: 2rem;
        color: white;
      }
    }
  }
}

.bottom_position {
  position: absolute;
  bottom: 0;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

@media screen and (max-width: 1000px) {
  .map_bg {
    height: 180rem;
  }

  .data_div {
    width: 100%;

    .data_top_div .items_div {
      height: 80rem;
    }

    .data_mid_div {
      .year {
        height: 8rem;
      }

      .item {
        img {
          height: 8rem;
        }
      }
    }
  }
}
</style>